/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSONObject: any;
  Latitude: any;
  LocalDate: any;
  LocalTime: any;
  Longitude: any;
  Time: any;
  TimeZone: any;
  UUID: any;
  Void: any;
};

export enum AccessBlockingTypeEnum {
  BLOCK = 'BLOCK',
  FREEZE = 'FREEZE'
}

export type ActivateContractorInput = {
  /** ssoId исполнителя. Клиент вызвавший запрос на активацию */
  readonly changerId: Scalars['UUID'];
  /** id контрактора в системе hrm */
  readonly contractorId: Scalars['UUID'];
};

export type ActiveTicketsFilter = {
  readonly assigneeSsoId?: InputMaybe<Scalars['UUID']>;
};

export type AddUserAddressInput = {
  readonly coordinates?: InputMaybe<CoordinatesInput>;
  readonly detail?: InputMaybe<AddressDetailInput>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly label?: InputMaybe<Scalars['String']>;
  readonly metro: ReadonlyArray<MetroInput>;
  readonly object: Scalars['String'];
  readonly type?: InputMaybe<AddressTypeEnum>;
  readonly unrestrictedValue: Scalars['String'];
  readonly value: Scalars['String'];
};

export type AddUserPhotoManagementInput = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly original?: InputMaybe<Scalars['String']>;
  readonly tag?: InputMaybe<Scalars['String']>;
  readonly thumb?: InputMaybe<Scalars['String']>;
  readonly url: Scalars['String'];
};

export type Address = {
  readonly __typename: 'Address';
  /** Address coordinates */
  readonly coordinates?: Maybe<Coordinates>;
  /** Address createdAt */
  readonly createdAt: Scalars['DateTime'];
  /** Address detail */
  readonly detail?: Maybe<AddressDetail>;
  /** Address id */
  readonly id: Scalars['ID'];
  /** Address isDeleted or not */
  readonly isDeleted: Scalars['Boolean'];
  /** Address isReadonly */
  readonly isReadonly: Scalars['Boolean'];
  /** Address label */
  readonly label?: Maybe<Scalars['String']>;
  /** Address metro */
  readonly metro: ReadonlyArray<Metro>;
  /** Address object */
  readonly object?: Maybe<Scalars['String']>;
  /** Address type */
  readonly type: AddressTypeEnum;
  /** Address unrestrictedValue */
  readonly unrestrictedValue?: Maybe<Scalars['String']>;
  /** Address value */
  readonly value: Scalars['String'];
};

export type AddressDetail = {
  readonly __typename: 'AddressDetail';
  /** AddressDetail area */
  readonly area?: Maybe<Scalars['String']>;
  /** AddressDetail block */
  readonly block?: Maybe<Scalars['String']>;
  /** AddressDetail city */
  readonly city?: Maybe<Scalars['String']>;
  /** AddressDetail comment */
  readonly comment?: Maybe<Scalars['String']>;
  /** AddressDetail entrance */
  readonly entrance?: Maybe<Scalars['String']>;
  /** AddressDetail flat */
  readonly flat?: Maybe<Scalars['String']>;
  /** AddressDetail floor */
  readonly floor?: Maybe<Scalars['String']>;
  /** AddressDetail house */
  readonly house?: Maybe<Scalars['String']>;
  /** AddressDetail intercom */
  readonly intercom?: Maybe<Scalars['String']>;
  /** AddressDetail region */
  readonly region?: Maybe<Scalars['String']>;
  /** населенный пункт */
  readonly settlement?: Maybe<Scalars['String']>;
  /** AddressDetail street */
  readonly street?: Maybe<Scalars['String']>;
};

export type AddressDetailInput = {
  readonly area?: InputMaybe<Scalars['String']>;
  readonly block?: InputMaybe<Scalars['String']>;
  readonly city?: InputMaybe<Scalars['String']>;
  readonly comment?: InputMaybe<Scalars['String']>;
  readonly entrance?: InputMaybe<Scalars['String']>;
  readonly flat?: InputMaybe<Scalars['String']>;
  readonly floor?: InputMaybe<Scalars['String']>;
  readonly house?: InputMaybe<Scalars['String']>;
  readonly intercom?: InputMaybe<Scalars['String']>;
  readonly region?: InputMaybe<Scalars['String']>;
  readonly settlement?: InputMaybe<Scalars['String']>;
  readonly street?: InputMaybe<Scalars['String']>;
};

export enum AddressTypeEnum {
  BIRTH_ADDRESS = 'BIRTH_ADDRESS',
  DEFAULT = 'DEFAULT',
  PICKUP_POINT = 'PICKUP_POINT',
  REG_ADDRESS = 'REG_ADDRESS',
  RESIDENCE_ADDRESS = 'RESIDENCE_ADDRESS'
}

export type ApplyTaskInTicketInput = {
  readonly assigneeSsoId: Scalars['UUID'];
  readonly taskId?: InputMaybe<Scalars['UUID']>;
};

export type ApplyTaskInput = {
  /** SSO id назначаемого оператора */
  readonly managerSsoId: Scalars['String'];
  /** id предпочтительной задачи */
  readonly taskId?: InputMaybe<Scalars['String']>;
};

export type Assignment = {
  readonly __typename: 'Assignment';
  /** SSO профиль исполнителя */
  readonly contractor?: Maybe<User>;
  /**
   * SSO идентификатор исполнителя
   * @deprecated No longer supported
   */
  readonly contractorId?: Maybe<Scalars['UUID']>;
  readonly createdAt: Scalars['DateTime'];
  readonly deletedAt?: Maybe<Scalars['DateTime']>;
  /** Запланированное окончание назначения */
  readonly endAt?: Maybe<Scalars['DateTime']>;
  /** Ранг */
  readonly gradeId?: Maybe<Scalars['UUID']>;
  readonly id: Scalars['UUID'];
  /** Должность */
  readonly positionId: Scalars['UUID'];
  /** Причина отказа исполнителем от назначения */
  readonly reasonForRejection?: Maybe<Scalars['String']>;
  /** Запланированное начало назначения */
  readonly startAt?: Maybe<Scalars['DateTime']>;
  readonly status: AssignmentStatusEnum;
  readonly updatedAt: Scalars['DateTime'];
  /** Тариф */
  readonly wageId?: Maybe<Scalars['UUID']>;
  /** Данные для связи с точкой доставкой */
  readonly waypoint?: Maybe<Waypoint>;
};

export enum AssignmentStatusEnum {
  /** Исполнитель выполняет заказ */
  AT_WORK = 'AT_WORK',
  /** Назначение подтверждено исполнителем */
  CONFIRMED = 'CONFIRMED',
  /** Исполнитель выполнил заказ */
  DONE = 'DONE',
  /** Ожидает возобновления работы над назначением */
  HOLD = 'HOLD',
  /** Назначение отклонено исполнителем */
  REJECTED = 'REJECTED',
  /** Ожидает подтверждения назначения исполнителем */
  WAITING = 'WAITING'
}

export type AssignmentStrategy = {
  readonly __typename: 'AssignmentStrategy';
  readonly createdAt: Scalars['DateTime'];
  readonly deletedAt?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['UUID'];
  readonly isDefault: Scalars['Boolean'];
  readonly isDeleted: Scalars['Boolean'];
  readonly items: ReadonlyArray<AssignmentStrategyItem>;
  readonly updatedAt: Scalars['DateTime'];
};

export type AssignmentStrategyItem = {
  readonly __typename: 'AssignmentStrategyItem';
  readonly factor?: Maybe<Scalars['Int']>;
  readonly id: Scalars['UUID'];
  readonly positions: ReadonlyArray<AssignmentStrategyPosition>;
};

export type AssignmentStrategyPosition = {
  readonly __typename: 'AssignmentStrategyPosition';
  readonly id: Scalars['UUID'];
  readonly positionId: Scalars['UUID'];
  readonly value: Scalars['Int'];
};

export type AuthorizeClientInput = {
  /** clientId приложения */
  readonly clientId: Scalars['String'];
  /** Тип соединения */
  readonly connection: ConnectionEnum;
  /** ip источника запроса */
  readonly ip: Scalars['String'];
  /** Логин в системе */
  readonly login: Scalars['String'];
  /** Пароль */
  readonly password: Scalars['String'];
  /** userAgent источника запроса */
  readonly userAgent?: InputMaybe<Scalars['String']>;
  /** Тип пользователя */
  readonly userType: UserTypeEnum;
};

export type AuthorizeInput = {
  /** Параметры авторизация для клиента (включают логин/пароль) */
  readonly client?: InputMaybe<AuthorizeClientInput>;
  /** Параметры авторизация других сервисов (включают секрет) */
  readonly machine?: InputMaybe<AuthorizeMachineInput>;
};

export type AuthorizeMachineInput = {
  /** clientId приложения */
  readonly clientId: Scalars['String'];
  /** clientSecret приложения */
  readonly clientSecret: Scalars['String'];
  /** Массив пермишеннов */
  readonly permissions: ReadonlyArray<Scalars['String']>;
};

export type AuthorizeResponse = {
  readonly __typename: 'AuthorizeResponse';
  readonly accessToken: Scalars['String'];
  /** expiresIn - timestamp string */
  readonly expiresIn: Scalars['String'];
  readonly idToken: Scalars['ID'];
  readonly refreshToken: Scalars['String'];
};

export type BaseOptionConstraint = {
  readonly __typename: 'BaseOptionConstraint';
  readonly id: Scalars['UUID'];
  /** Минимальное кол-во */
  readonly necessaryVal: Scalars['Int'];
  /** Опция */
  readonly option: Option;
  /** Тариф продукта */
  readonly priceProduct: PriceProduct;
};

export type BatchGeoInfo = {
  readonly __typename: 'BatchGeoInfo';
  readonly geo: Geo;
  /** Id координат из запроса */
  readonly id: Scalars['String'];
};

export type BatchGeoInput = {
  readonly geoInput: GeoInput;
  /** Id координат, чтобы отличить их в ответе */
  readonly id: Scalars['String'];
};

export type BlockOrFreezeContractorInput = {
  /** ssoId исполнителя. Клиент вызвавший запрос на блокировку/заморозку */
  readonly changerId: Scalars['UUID'];
  /** id контрактора в системе hrm */
  readonly contractorId: Scalars['UUID'];
  /** id причины блокировки/заморозки */
  readonly reasonId: Scalars['UUID'];
};

export type BlockingReason = {
  readonly __typename: 'BlockingReason';
  /** id */
  readonly id: Scalars['ID'];
  /** Название причины */
  readonly name: Scalars['String'];
  /** Тип блокировки. Определяет заморозить или заблокировать пользователя */
  readonly type?: Maybe<BlockingTypeEnum>;
};

export type BlockingReasonGroup = {
  readonly __typename: 'BlockingReasonGroup';
  /** Причины входящие в эту группу */
  readonly blockingReasons: ReadonlyArray<BlockingReason>;
  /** id */
  readonly id: Scalars['ID'];
  /** Название группы */
  readonly name: Scalars['String'];
};

export enum BlockingTypeEnum {
  BLOCK = 'BLOCK',
  FREEZE = 'FREEZE'
}

export type CallActionInput = {
  /** Slug доступного действия */
  readonly actionSlug: Scalars['String'];
  /** Параметры заказа */
  readonly params?: InputMaybe<Scalars['JSONObject']>;
};

export type CallActionOrderOptions = {
  readonly orderOptionId: Scalars['String'];
  readonly value: Scalars['Int'];
};

export type CancelTaskInput = {
  /** id причины отмены */
  readonly cancelReasonId: Scalars['String'];
  /** комментарий */
  readonly comment?: InputMaybe<Scalars['String']>;
  /** id задачи */
  readonly taskId: Scalars['UUID'];
};

export enum CancellationReasonEnum {
  BUSY = 'BUSY',
  NO_PICKUP = 'NO_PICKUP',
  NUMBER_NOT_EXISTS = 'NUMBER_NOT_EXISTS',
  UNAVAILABLE = 'UNAVAILABLE'
}

export type CargoItem = {
  readonly __typename: 'CargoItem';
  readonly count: Scalars['Int'];
  readonly slug?: Maybe<Scalars['String']>;
};

export type Cart = {
  readonly __typename: 'Cart';
  readonly id: Scalars['ID'];
  /**
   * Идентификатор специального предложения
   * Присутствует в реферальной ссылке
   * Влияет на итоговую стоимость заказов в корзине
   */
  readonly offerId?: Maybe<Scalars['UUID']>;
  /** Заказы в корзине */
  readonly orders: ReadonlyArray<Order>;
  /** Уникальный номер корзины */
  readonly serialNumber: Scalars['Int'];
  /** Общая стоимость корзины без учета скидок */
  readonly subTotalPrice: Scalars['Int'];
  /** Общее время выполнения заказов в корзине */
  readonly totalDuration: Scalars['Int'];
  /** Общая стоимость корзины */
  readonly totalPrice: Scalars['Int'];
};

export type CartFilter = {
  /** ID корзины */
  readonly id: ReadonlyArray<Scalars['UUID']>;
};

export type Category = {
  readonly __typename: 'Category';
  readonly id: Scalars['ID'];
  /** Список типов входящих в данную категорию */
  readonly subCategories: ReadonlyArray<SubCategory>;
  readonly title: Scalars['String'];
  readonly type: CategoryTypeEnum;
};

export enum CategoryTypeEnum {
  BUG = 'BUG',
  CONSULTATION = 'CONSULTATION',
  ORDER = 'ORDER',
  ORDER_PROBLEM = 'ORDER_PROBLEM',
  PROFILE = 'PROFILE'
}

export type CloneCartInput = {
  /** ID корзины */
  readonly cartId: Scalars['UUID'];
};

/**
 * Здесь должен быть заказ из OMS. В данный момент здесь такая модель заказа, так как здесь хранятся данные из заказов из OMS и CRM.
 * serialNumber мы вынуждены хранить для фронта, так как обращаться по каждому заказу в OMS мы не можем (его там может не быть)
 * Как только все заказы будут в OMS, здесь будет ссылка на тип Order из OMS и все кроме id будет резолвиться из него
 * IsExternal - если true, то заказ из CRM
 */
export type CommonOrder = {
  readonly __typename: 'CommonOrder';
  readonly id: Scalars['ID'];
  readonly isExternal: Scalars['Boolean'];
  readonly serialNumber: Scalars['Int'];
};

export type CompleteWorkWithUserInput = {
  /** Передаем id исполнителя для того чтобы закрыть все активные тикеты. Тикеты могут быть захолдированы, закрыты или будет выброшено исключения, так как задачи в тикете находятся в работе */
  readonly assigneeSsoId: Scalars['UUID'];
};

export type ConfirmCartInput = {
  /** ID корзины */
  readonly cartId: Scalars['UUID'];
};

export enum ConnectionEnum {
  CREDENTIAL = 'CREDENTIAL',
  M2M = 'M2M'
}

export type Contact = {
  readonly __typename: 'Contact';
  /** Contact id */
  readonly id: Scalars['ID'];
  /** Contact isMain */
  readonly isMain: Scalars['Boolean'];
  /** Contact isVerified */
  readonly isVerified: Scalars['Boolean'];
  /** Contact type */
  readonly type: ContactTypeEnum;
  /** Contact value */
  readonly value: Scalars['String'];
};

export enum ContactTypeEnum {
  EMAIL = 'EMAIL',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  LINKED_IN = 'LINKED_IN',
  MOBILE = 'MOBILE',
  MY_MAIL = 'MY_MAIL',
  OK = 'OK',
  TELEGRAM = 'TELEGRAM',
  TWITTER = 'TWITTER',
  VK = 'VK',
  WHATSAPP = 'WHATSAPP',
  YOUTUBE = 'YOUTUBE'
}

export type Contractor = {
  readonly __typename: 'Contractor';
  /** id в системе hrm */
  readonly id: Scalars['ID'];
  /** Рейтинг исполнителя */
  readonly rating: Scalars['Float'];
  /** Статус персоны */
  readonly status: PersonStatusEnum;
  /** ssoId */
  readonly userId: Scalars['UUID'];
  /** Статус в системе */
  readonly workStatus: WorkStatusEnum;
};

export type ContractorFilter = {
  /** Поиск по ssoId пользователя */
  readonly userId: Scalars['UUID'];
};

export type ContractorForOrder = {
  readonly __typename: 'ContractorForOrder';
  /** Расстояние между контрактором и адресом заказа */
  readonly distance: Scalars['Float'];
  /** Является ли контрактор фаворитным для клиента */
  readonly isFavorite: Scalars['Boolean'];
  /** Рейтинг контрактора в системе */
  readonly rating: Scalars['Float'];
  /** Релевантность клинера для заказа. Чем более - тем релевантнее клинер для этого заказа */
  readonly relevance: Scalars['Float'];
  /** Скилл контрактора */
  readonly skill: Scalars['String'];
  /** Профиль в sso */
  readonly ssoProfile: User;
};

export type ContractorsForOrderInput = {
  /** Дата назначения, на которую хотим найти клинера */
  readonly date: Scalars['DateTime'];
  /** Id заказа на который подбираем клинера */
  readonly orderId: Scalars['String'];
  /** id позиции клинера */
  readonly positionId?: InputMaybe<Scalars['String']>;
};

export type ContractorsMonolithOrdersCountInput = {
  /** id клинера из монолита */
  readonly cleaner_id: Scalars['Int'];
};

export type Coordinates = {
  readonly __typename: 'Coordinates';
  /** Coordinates lat */
  readonly lat: Scalars['Float'];
  /** Coordinates lng */
  readonly lng: Scalars['Float'];
};

export type CoordinatesInput = {
  readonly lat: Scalars['Float'];
  readonly lng: Scalars['Float'];
};

export type CreateCartInput = {
  /** Список моделей для создания заказа */
  readonly orders?: InputMaybe<ReadonlyArray<CreateOrderInput>>;
};

export type CreateContactsInput = {
  /** Contact isMain */
  readonly isMain: Scalars['Boolean'];
  /** Contact isVerified */
  readonly isVerified: Scalars['Boolean'];
  /** Тип контакта */
  readonly type: ContactTypeEnum;
  /** Номер телефона/email и тд */
  readonly value: Scalars['String'];
};

export type CreateEntityChangeInput = {
  readonly creatorId: Scalars['UUID'];
  readonly data: Scalars['JSONObject'];
  readonly entityId: Scalars['UUID'];
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
};

export type CreateOrderInput = {
  /** ID корзины */
  readonly cartId?: InputMaybe<Scalars['UUID']>;
  /** ID пользователя заказчика в SSO */
  readonly customerId: Scalars['UUID'];
  /** Слаг продукта */
  readonly elementSlug?: InputMaybe<ElementSlugEnum>;
  /** ID продукта */
  readonly productId?: InputMaybe<Scalars['UUID']>;
};

export type CreateTaskInTicket = {
  readonly assigneeSsoId?: InputMaybe<Scalars['UUID']>;
  readonly clientSsoId?: InputMaybe<Scalars['UUID']>;
  readonly comment?: InputMaybe<Scalars['String']>;
  readonly communicationChannel?: InputMaybe<Scalars['String']>;
  readonly creatorSsoId?: InputMaybe<Scalars['UUID']>;
  readonly isExternal?: InputMaybe<Scalars['Boolean']>;
  readonly orderId?: InputMaybe<Scalars['UUID']>;
  readonly serialNumber?: InputMaybe<Scalars['String']>;
  readonly subCategoryId: Scalars['UUID'];
  readonly ticketId?: InputMaybe<Scalars['UUID']>;
};

export type CreateUserProfileInput = {
  readonly birthday?: InputMaybe<Scalars['String']>;
  readonly contacts: ReadonlyArray<CreateContactsInput>;
  readonly firstName?: InputMaybe<Scalars['String']>;
  readonly gender?: InputMaybe<GenderTypeEnum>;
  readonly isValid?: InputMaybe<Scalars['Boolean']>;
  readonly lastName?: InputMaybe<Scalars['String']>;
  readonly middleName?: InputMaybe<Scalars['String']>;
  readonly object?: InputMaybe<DadataFioInput>;
  readonly userType: UserTypeEnum;
};

export type DadataFio = {
  readonly __typename: 'DadataFIO';
  readonly data?: Maybe<DadataFioData>;
  readonly unrestrictedValue?: Maybe<Scalars['String']>;
  readonly value?: Maybe<Scalars['String']>;
};

export type DadataFioData = {
  readonly __typename: 'DadataFIOData';
  readonly gender?: Maybe<Scalars['String']>;
  readonly name?: Maybe<Scalars['String']>;
  readonly patronymic?: Maybe<Scalars['String']>;
  readonly qc?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly surname?: Maybe<Scalars['String']>;
};

export type DadataFioDataInput = {
  readonly gender?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly patronymic?: InputMaybe<Scalars['String']>;
  readonly qc?: InputMaybe<Scalars['String']>;
  readonly source?: InputMaybe<Scalars['String']>;
  readonly surname?: InputMaybe<Scalars['String']>;
};

export type DadataFioInput = {
  readonly data?: InputMaybe<DadataFioDataInput>;
  readonly unrestrictedValue?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
};

export type DashboardTicketsFilter = {
  readonly userId?: InputMaybe<Scalars['UUID']>;
};

export type DateTimeInfo = {
  readonly __typename: 'DateTimeInfo';
  /** Конвертирование в локальную таймзону */
  readonly localDateTime: LocalDateTime;
  /** Таймзона. Пример: Africa/Ceuta */
  readonly timeZone: Scalars['TimeZone'];
};


export type DateTimeInfoLocalDateTimeArgs = {
  dateTime: Scalars['DateTime'];
};

export type DeleteSurgeInput = {
  readonly dateEnd: Scalars['DateTime'];
  readonly dateStart: Scalars['DateTime'];
  readonly element: Scalars['String'];
  readonly regionId: Scalars['Int'];
};

export type DeleteUserPhotoManagementInput = {
  readonly id: Scalars['ID'];
};

export enum ElementSlugEnum {
  AGENT_DRYCLEANING = 'AGENT_DRYCLEANING',
  AGENT_DRYCLEANING_CARPET = 'AGENT_DRYCLEANING_CARPET',
  AGENT_DRYCLEANING_CURTAINS = 'AGENT_DRYCLEANING_CURTAINS',
  AGENT_DRYCLEANING_STROLLER = 'AGENT_DRYCLEANING_STROLLER',
  PLT_DRYCLEANING_CARPET = 'PLT_DRYCLEANING_CARPET',
  PLT_DRYCLEANING_CURTAINS = 'PLT_DRYCLEANING_CURTAINS',
  PLT_DRYCLEANING_STROLLER = 'PLT_DRYCLEANING_STROLLER',
  SELL_GOODS_CHEMISTRY_NEW = 'SELL_GOODS_CHEMISTRY_NEW',
  SELL_GOODS_NEW = 'SELL_GOODS_NEW'
}

export type EntityChange = {
  readonly __typename: 'EntityChange';
  /** Id создателя */
  readonly creatorId: Scalars['UUID'];
  /** Свободная структура изменения */
  readonly data?: Maybe<Scalars['JSONObject']>;
  /** Id изменяемого объекта */
  readonly entityId: Scalars['UUID'];
  /** Время жизни изменения */
  readonly expirationDate?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['UUID'];
};

export type EntityChangesFilter = {
  readonly entityId: Scalars['UUID'];
};

export type EntityChangesResponse = {
  readonly __typename: 'EntityChangesResponse';
  readonly entityChanges?: Maybe<ReadonlyArray<EntityChange>>;
};

export enum EntityTypeEnum {
  /** Не используется в данный момент */
  ORDER = 'ORDER',
  /** Задача создана по проспекту из сервиса crm-core-svc */
  PROSPECT = 'PROSPECT',
  /** Тикеты из юздеска. Не используются в тикетной системе!!! */
  TICKET = 'TICKET',
  /** В тикетную систему приходят только с данным типом. Тип TICKET значит, что задача привязана к тикету */
  TICKET_SYSTEM = 'TICKET_SYSTEM'
}

export type ExecutionHistoryItem = {
  readonly __typename: 'ExecutionHistoryItem';
  readonly createdAt: Scalars['DateTime'];
  readonly id: Scalars['String'];
  readonly kind: ExecutionHistoryItemKindEnum;
  readonly manager?: Maybe<Manager>;
  readonly taskStateValue?: Maybe<TaskStateEnum>;
};

export enum ExecutionHistoryItemKindEnum {
  /** Добавление задачи в список */
  ADD_TASK_TO_TASK_LIST = 'ADD_TASK_TO_TASK_LIST',
  /** Отмена коммуникации */
  CANCEL_COMMUNICATION = 'CANCEL_COMMUNICATION',
  /** Изменение заказа */
  CHANGE_ORDER = 'CHANGE_ORDER',
  /** Изменение состояния */
  CHANGE_STATE = 'CHANGE_STATE',
  /** Изменение владельца задачи */
  CHANGE_TASK_OWNER = 'CHANGE_TASK_OWNER',
  /** Изменение статуса менеджера */
  MANAGER_ONLINE = 'MANAGER_ONLINE'
}

export type FinanceProcess = {
  readonly __typename: 'FinanceProcess';
  readonly id: Scalars['ID'];
  /** Ссылка на оплату по софтпос */
  readonly link?: Maybe<Scalars['String']>;
};

export type FindManagerInput = {
  /** ssoId менеджера */
  readonly id: Scalars['UUID'];
};

export type ForwardPaginationOptionsInput = {
  readonly after?: InputMaybe<Scalars['String']>;
  readonly first?: InputMaybe<Scalars['Int']>;
};

export type ForwardingOptionsInput = {
  readonly pagination?: InputMaybe<ForwardPaginationOptionsInput>;
};

export enum GenderTypeEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

/** Локация */
export type Geo = {
  readonly __typename: 'Geo';
  /** Таймзона и операции над датой и временем */
  readonly dateTime: DateTimeInfo;
};

export type GeoInput = {
  readonly lat: Scalars['Latitude'];
  readonly long: Scalars['Longitude'];
};

export type LaundryOrderReviewedOptionsInput = {
  readonly isFree?: InputMaybe<Scalars['Boolean']>;
  readonly slug?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['Int']>;
};

export type Link = Cart | CommonOrder;

export enum LinkTypeEnum {
  Order = 'Order'
}

export type LinkedEntity = {
  readonly __typename: 'LinkedEntity';
  readonly id: Scalars['ID'];
  readonly type: LinkedEntityTypeEnum;
};

export enum LinkedEntityTypeEnum {
  ORDER = 'ORDER'
}

export type LinkedTaskEntityInput = {
  readonly entityId: Scalars['UUID'];
  readonly entityType: EntityTypeEnum;
};

export type LmsOrder = {
  readonly __typename: 'LmsOrder';
  readonly id: Scalars['ID'];
  readonly lmsOrderId: Scalars['UUID'];
};

export type LocalDateTime = {
  readonly __typename: 'LocalDateTime';
  /** Локальная дата YYYY-MM-DD */
  readonly date: Scalars['LocalDate'];
  /** Таймстемп с таймзоной в виде 2022-12-23T13:15:30+03:00 */
  readonly iso: Scalars['String'];
  /** Локальное время HH:mm */
  readonly time: Scalars['LocalTime'];
};

/** Владелец задачи */
export type Manager = {
  readonly __typename: 'Manager';
  readonly id: Scalars['ID'];
  /** Канал связи */
  readonly managerCommunicationChannel?: Maybe<ManagerCommunicationChannel>;
  /** Имя в CRM */
  readonly name: Scalars['String'];
  /** Идентификатор в SSO */
  readonly ssoUid: Scalars['UUID'];
  /** Состояние в системе CRM */
  readonly state: ManagerStateEnum;
};

export type ManagerCommunicationChannel = {
  readonly __typename: 'ManagerCommunicationChannel';
  readonly communicationChannel: ManagerCommunicationChannelEnum;
};

export enum ManagerCommunicationChannelEnum {
  CALL = 'CALL',
  CHAT = 'CHAT'
}

/** Состояние оператора в системе CRM */
export enum ManagerStateEnum {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE'
}

export enum MatrixUnitType {
  /** за услугу */
  SERVICE = 'SERVICE',
  /** за шт */
  UNIT = 'UNIT'
}

export type Metro = {
  readonly __typename: 'Metro';
  /** Metro distance */
  readonly distance?: Maybe<Scalars['Float']>;
  /** Metro line */
  readonly line?: Maybe<Scalars['String']>;
  /** Metro name */
  readonly name?: Maybe<Scalars['String']>;
};

export type MetroInput = {
  readonly distance: Scalars['Float'];
  readonly line: Scalars['String'];
  readonly name: Scalars['String'];
};

export type Mutation = {
  readonly __typename: 'Mutation';
  readonly activateContractor?: Maybe<Scalars['Void']>;
  /** Создать новый адресс */
  readonly addUserAddress: ReadonlyArray<Address>;
  /** Добавить фото */
  readonly addUserPhotoManagement?: Maybe<Scalars['Void']>;
  /** Взятие задачи в работу */
  readonly applyTask: Task;
  /** Взять задачу в работу. Если тикет не открыт, после того как задача из него взята в работу он будет открыт. Асинхронная мутация */
  readonly applyTaskInTicket: RunningOperation;
  /** Получение токена */
  readonly authorize: AuthorizeResponse;
  readonly blockOrFreezeContractor?: Maybe<Scalars['Void']>;
  /** Вызов ДД */
  readonly callOrderActions: OrderActionMutations;
  /** Отмена задачи */
  readonly cancelTask: Task;
  /** Создание корзины на основе существующей корзины */
  readonly cloneCart: Cart;
  /** Закрыть или отправить в ожидание тикет. Синхронная мутация */
  readonly completeWorkWithUser?: Maybe<Scalars['Void']>;
  /**
   * Подтверждение корзины
   * Каждый заказ корзины перейдет в статус TODO
   * Для каждого заказа будут созданы назначения
   */
  readonly confirmCart: Cart;
  /** Создание корзины */
  readonly createCart: Cart;
  /** Создание слепка изменений */
  readonly createEntityChange: EntityChange;
  /** Создание заказа */
  readonly createOrder: Order;
  /** Создание задачи */
  readonly createTask: Task;
  /** Регистрация проблемы вручную. Тикет и задача генерируются автоматически. Асинхронная мутация */
  readonly createTaskInTicket: RunningOperation;
  /** Создать тикет. Синхронная мутация */
  readonly createTicket: Ticket;
  /** Создать пользователя. Необходимо сразу прокидывать контакты, так как после создания добавить контакты не получится */
  readonly createUserProfile: User;
  readonly deleteSurge?: Maybe<Scalars['Void']>;
  /** Удалить фото */
  readonly deleteUserPhotoManagement?: Maybe<Scalars['Void']>;
  /** Перевод задачи в недозвон */
  readonly postponeTask: Task;
  /** Выполнить задачу */
  readonly proceedTask: Task;
  /** Обновить токен на основе refreshToken полученного при авторизации */
  readonly refreshToken: AuthorizeResponse;
  /** Удаление корзины с заказами */
  readonly removeCart: Scalars['Boolean'];
  /** Перевод задачи в перезвон */
  readonly rescheduleTask: Task;
  /** Отзыв персональных данных */
  readonly revocationPersonalData?: Maybe<Scalars['Void']>;
  /** Обновление слепка изменений */
  readonly updateEntityChange: EntityChange;
  /** Обновление заказа */
  readonly updateOrder: Order;
  /**
   * Обновление адреса заказа
   *
   * В качестве addressId используется адрес из SSO
   */
  readonly updateOrderAddress: Order;
  /**
   * Выбор стратегии назначений
   *
   * Для каждого продукта есть стратегия по умолчанию
   */
  readonly updateOrderAssignmentStrategy: Order;
  /** Обновление задачи */
  readonly updateTask: Task;
  /** Обновить информацию по адресу */
  readonly updateUserAddress: ReadonlyArray<Address>;
  /** Обновить фото */
  readonly updateUserPhotoManagement?: Maybe<Scalars['Void']>;
  /** Обновить информацию профиля */
  readonly updateUserProfileInfo: User;
  /**
   * Обновлении опции заказа
   *
   * value: 0|null уберет опцию из заказа
   */
  readonly upsertOrderOption: Order;
  readonly upsertSurge?: Maybe<Scalars['Void']>;
};


export type MutationActivateContractorArgs = {
  input?: InputMaybe<ActivateContractorInput>;
};


export type MutationAddUserAddressArgs = {
  addressInput: AddUserAddressInput;
  userId: Scalars['UUID'];
};


export type MutationAddUserPhotoManagementArgs = {
  input: AddUserPhotoManagementInput;
};


export type MutationApplyTaskArgs = {
  input: ApplyTaskInput;
};


export type MutationApplyTaskInTicketArgs = {
  input?: InputMaybe<ApplyTaskInTicketInput>;
};


export type MutationAuthorizeArgs = {
  input: AuthorizeInput;
};


export type MutationBlockOrFreezeContractorArgs = {
  input?: InputMaybe<BlockOrFreezeContractorInput>;
};


export type MutationCallOrderActionsArgs = {
  orderId: Scalars['UUID'];
};


export type MutationCancelTaskArgs = {
  input: CancelTaskInput;
};


export type MutationCloneCartArgs = {
  input: CloneCartInput;
};


export type MutationCompleteWorkWithUserArgs = {
  input?: InputMaybe<CompleteWorkWithUserInput>;
};


export type MutationConfirmCartArgs = {
  input: ConfirmCartInput;
};


export type MutationCreateCartArgs = {
  input: CreateCartInput;
};


export type MutationCreateEntityChangeArgs = {
  input?: InputMaybe<CreateEntityChangeInput>;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationCreateTaskArgs = {
  input: TaskCreateInput;
};


export type MutationCreateTaskInTicketArgs = {
  input: CreateTaskInTicket;
};


export type MutationCreateTicketArgs = {
  ticketCreateInput: TicketCreateInput;
};


export type MutationCreateUserProfileArgs = {
  input: CreateUserProfileInput;
};


export type MutationDeleteSurgeArgs = {
  input: DeleteSurgeInput;
};


export type MutationDeleteUserPhotoManagementArgs = {
  input: DeleteUserPhotoManagementInput;
};


export type MutationPostponeTaskArgs = {
  input: PostponeTaskInput;
};


export type MutationProceedTaskArgs = {
  input: ProceedTaskInput;
};


export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};


export type MutationRemoveCartArgs = {
  input: RemoveCartInput;
};


export type MutationRescheduleTaskArgs = {
  input: RescheduleTaskInput;
};


export type MutationRevocationPersonalDataArgs = {
  input: RevocationPersonalDataInput;
};


export type MutationUpdateEntityChangeArgs = {
  input?: InputMaybe<UpdateEntityChangeInput>;
  where?: InputMaybe<UpdateEntityChangeWhere>;
};


export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};


export type MutationUpdateOrderAddressArgs = {
  input: UpdateOrderAddressInput;
};


export type MutationUpdateOrderAssignmentStrategyArgs = {
  input: UpdateOrderAssignmentStrategyInput;
};


export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};


export type MutationUpdateUserAddressArgs = {
  addressInput: UpdateUserAddressInput;
  userId: Scalars['UUID'];
};


export type MutationUpdateUserPhotoManagementArgs = {
  input: UpdateUserPhotoManagementInput;
};


export type MutationUpdateUserProfileInfoArgs = {
  input: UpdateUserProfileInfoInput;
};


export type MutationUpsertOrderOptionArgs = {
  input: UpsertOrderOptionInput;
};


export type MutationUpsertSurgeArgs = {
  input: UpsertSurgeInput;
};

export type Option = {
  readonly __typename: 'Option';
  /** Имя иконки */
  readonly iconName?: Maybe<Scalars['String']>;
  readonly id: Scalars['UUID'];
  /** Признак того, что опция является грузом, только опции с признаком isCargo = true могут быть добавлены в ЛМС заказ в качестве груза */
  readonly isCargo: Scalars['Boolean'];
  /** Человеко-читаемое имя */
  readonly name: Scalars['String'];
  /** Порядковый номер */
  readonly order: Scalars['Int'];
  /** Данные о стоимости и продолжительности */
  readonly priceOption: PriceOption;
  /** Человеко-читаемое описание */
  readonly shortDesc?: Maybe<Scalars['String']>;
  /** Уникальное короткое обозначение опции */
  readonly slug: Scalars['String'];
  /** Услуга или товар */
  readonly type: OptionTypeEnum;
};

export enum OptionTypeEnum {
  /** Товар */
  GOODS = 'GOODS',
  /** Услуга */
  SERVICE = 'SERVICE'
}

export type Order = {
  readonly __typename: 'Order';
  /** Адрес выполнения заказа */
  readonly address?: Maybe<OrderAddress>;
  /**
   * Стратегия по которой создаются назначения
   * От нее зависит кол-во и требования назначений
   */
  readonly assignmentStrategy: AssignmentStrategy;
  /** Назначения для исполнителей созданные для выполнения заказа */
  readonly assignments: ReadonlyArray<Assignment>;
  readonly bonusesUsed?: Maybe<Scalars['Int']>;
  /** Дата отмены заказа */
  readonly canceledAt?: Maybe<Scalars['DateTime']>;
  /** Id корзины */
  readonly cartId: Scalars['UUID'];
  /** Комментарий для исполнителя */
  readonly commentForContractor?: Maybe<Scalars['String']>;
  /** Дата выполнения заказа */
  readonly competedAt?: Maybe<Scalars['DateTime']>;
  readonly createdAt: Scalars['DateTime'];
  /** SSO идентификатор заказчика */
  readonly customerId: Scalars['UUID'];
  readonly deletedAt?: Maybe<Scalars['DateTime']>;
  /** Фин процесс. Возвращаем только один, который активный */
  readonly financeProcesses: ReadonlyArray<FinanceProcess>;
  readonly id: Scalars['UUID'];
  readonly isDeleted: Scalars['Boolean'];
  /** Связь с LMS заказом, храним только id лмс заказа */
  readonly lmsOrder?: Maybe<LmsOrder>;
  /** Опции продукта, добавленные к заказу */
  readonly options: ReadonlyArray<OrderOption>;
  /** Статус оплаты заказа */
  readonly paymentStatus?: Maybe<OrderPaymentStatusEnum>;
  /** Способ оплаты */
  readonly paymentType?: Maybe<OrderPaymentTypeEnum>;
  /**
   * В зависимости от выбранного способа оплаты:
   * - если картой, то идентификатор карты из finance-acquiring
   */
  readonly paymentTypeMeta?: Maybe<Scalars['String']>;
  /**
   * Время исполнителя на подготовку к выполнению заказа в минутах
   * Складывается из время на дорогу и выполнения таких опций как забор ключей
   */
  readonly prepDuration?: Maybe<Scalars['Int']>;
  /** Тариф продукта на который был сделан заказ */
  readonly priceProduct?: Maybe<PriceProduct>;
  /** Продукт на который был сделан заказ */
  readonly product: Product;
  readonly promocode?: Maybe<Scalars['String']>;
  /** Причина отказа */
  readonly reasonForRejection?: Maybe<ReasonForRejection>;
  /** Ссылка на квитанцию по заказу */
  readonly receiptLink?: Maybe<Scalars['String']>;
  /** Идентификатор региона, в котором заказ выполняется */
  readonly regionId?: Maybe<Scalars['UUID']>;
  /** Уникальный номер заказа */
  readonly serialNumber: Scalars['Int'];
  /** Уникальное короткое обозначение продукта */
  readonly slug: Scalars['String'];
  /** Время начала выполнения заказа */
  readonly startDate?: Maybe<Scalars['DateTime']>;
  readonly status?: Maybe<OrderStatusEnum>;
  /** Стоимость выполнения заказа с опциями в копейках без скидок */
  readonly subTotalPrice?: Maybe<Scalars['Int']>;
  /**
   * Время исполнителя на выполнение заказа в минутах
   * Учитывает время на такие опции как доставка ключей
   */
  readonly totalDuration?: Maybe<Scalars['Int']>;
  /** Итоговая стоимость выполнения заказа с опциями в копейках */
  readonly totalPrice?: Maybe<Scalars['Int']>;
  readonly updatedAt: Scalars['DateTime'];
  /** Будут ли исполльзованы бонусы в заказе */
  readonly useBonuses: Scalars['Boolean'];
};

export type OrderActionMutations = {
  readonly __typename: 'OrderActionMutations';
  /** ДД Поменять метод оплаты в заказе */
  readonly callChangePaymentType: OrderOperationResult;
  /** ДД Исполнитель отмечается на объекте и начинает работу */
  readonly callCheckInOrder: OrderOperationResult;
  /** ДД Исполнитель заканчивает работу на объекте */
  readonly callCheckOutOrder: OrderOperationResult;
  /** ДД Исполнитель хочет позвонить клиенту по прямому номеру */
  readonly callContractorDirectCallToCustomer: OrderOperationResult;
  /** ДД Исполнитель берёт заказ */
  readonly callContractorGetOrder: OrderOperationResult;
  /** ДД Исполнитель отказывается от заказа */
  readonly callContractorRejectAssignment: OrderOperationResult;
  /** Вызов кастомного доступного действия */
  readonly callCustomAction: OrderOperationResult;
  /** ДД Клиент хочет позвонить исполнителю по прямому номеру */
  readonly callCustomerDirectCallToContractor: OrderOperationResult;
  /** ДД Отмена заказа клиентом */
  readonly callCustomerOrderCancel: OrderOperationResult;
  /** ДД Менеджер добавяет промокод в заказ */
  readonly callEmployeeAddPromocodeToOrder: OrderOperationResult;
  /** ДД Назначить исполнителя на заказ менеджером */
  readonly callEmployeeAssignContractorToOrder: OrderOperationResult;
  /** ДД Отмена заказа менеджером */
  readonly callEmployeeCancelOrder: OrderOperationResult;
  /** ДД Менеджер отмечает на объекте и начинает работу исполнителя */
  readonly callEmployeeCheckInContractorOrder: OrderOperationResult;
  /** ДД Менеджер завершает заказ за исполнителя */
  readonly callEmployeeCheckOutContractorOrder: OrderOperationResult;
  /** ДД Снять исполнителя с заказа менеджером */
  readonly callEmployeeRejectContractorAssignment: OrderOperationResult;
  /** ДД Менеджер отправляет платежную ссылку */
  readonly callEmployeeRepeatPayment: OrderOperationResult;
  /** Начать выполнение назначения */
  readonly checkInAssignment?: Maybe<Scalars['Void']>;
  /** Завершить назначение */
  readonly checkOutAssignment?: Maybe<Scalars['Void']>;
  /** Назначить исполнителя логистом в максоптре: используем только на стейдже!!! */
  readonly employeeAssignContractorToAssignment?: Maybe<Scalars['Void']>;
  /** Снять исполнителя логистом в максоптре: используем только на стейдже!!! */
  readonly employeeUnassignContractorFromAssignment?: Maybe<Scalars['Void']>;
  /** Приостановить маршрут */
  readonly holdAssignment?: Maybe<Scalars['Void']>;
  /** Отмена заказа хч */
  readonly laundryCancelOrder?: Maybe<Scalars['Void']>;
  /** Создать доставку вещей из фабрики */
  readonly laundryCreateDelivery?: Maybe<Scalars['Void']>;
  /** Заказ согласован */
  readonly laundryOrderAccepted?: Maybe<Scalars['Void']>;
  /** Заказ прошел приемку на фабрике. Квитанция будет обновлена только если передана новая ссылка, пустое значение не сотрет уже сохраненную */
  readonly laundryOrderReviewed?: Maybe<Scalars['Void']>;
  /** Поддержка отменяет назначение */
  readonly laundryRejectAssignment?: Maybe<Scalars['Void']>;
  /** ID заказа */
  readonly orderId: Scalars['UUID'];
  /** Изменить назначение */
  readonly updateAssignment?: Maybe<Scalars['Void']>;
  /** Обновить опции в заказе(если заказ уже подтвержден обновлять можно только через ДД) */
  readonly updateOptions?: Maybe<Scalars['Void']>;
};


export type OrderActionMutationsCallChangePaymentTypeArgs = {
  paymentType: OrderPaymentTypeEnum;
  paymentTypeMeta?: InputMaybe<Scalars['String']>;
};


export type OrderActionMutationsCallCheckInOrderArgs = {
  assignmentId: Scalars['UUID'];
};


export type OrderActionMutationsCallCheckOutOrderArgs = {
  assignmentId: Scalars['UUID'];
};


export type OrderActionMutationsCallContractorGetOrderArgs = {
  assignmentId: Scalars['UUID'];
};


export type OrderActionMutationsCallContractorRejectAssignmentArgs = {
  assignmentId: Scalars['UUID'];
  reasonForRejectionId: Scalars['UUID'];
};


export type OrderActionMutationsCallCustomActionArgs = {
  input: CallActionInput;
};


export type OrderActionMutationsCallCustomerDirectCallToContractorArgs = {
  contractorId: Scalars['UUID'];
};


export type OrderActionMutationsCallCustomerOrderCancelArgs = {
  reasonForRejectionId?: InputMaybe<Scalars['UUID']>;
};


export type OrderActionMutationsCallEmployeeAddPromocodeToOrderArgs = {
  promocode: Scalars['String'];
};


export type OrderActionMutationsCallEmployeeAssignContractorToOrderArgs = {
  assignmentId: Scalars['UUID'];
  contractorId: Scalars['UUID'];
};


export type OrderActionMutationsCallEmployeeCancelOrderArgs = {
  reasonForRejectionId?: InputMaybe<Scalars['UUID']>;
};


export type OrderActionMutationsCallEmployeeCheckInContractorOrderArgs = {
  assignmentId: Scalars['UUID'];
  contractorId: Scalars['UUID'];
};


export type OrderActionMutationsCallEmployeeCheckOutContractorOrderArgs = {
  contractorId: Scalars['UUID'];
};


export type OrderActionMutationsCallEmployeeRejectContractorAssignmentArgs = {
  contractorId: Scalars['UUID'];
  reasonForRejectionId?: InputMaybe<Scalars['UUID']>;
};


export type OrderActionMutationsCheckInAssignmentArgs = {
  assignmentId: Scalars['UUID'];
};


export type OrderActionMutationsCheckOutAssignmentArgs = {
  assignmentId: Scalars['UUID'];
};


export type OrderActionMutationsEmployeeAssignContractorToAssignmentArgs = {
  assignmentId: Scalars['UUID'];
  contractorId: Scalars['UUID'];
};


export type OrderActionMutationsEmployeeUnassignContractorFromAssignmentArgs = {
  assignmentId: Scalars['UUID'];
  contractorId: Scalars['UUID'];
};


export type OrderActionMutationsHoldAssignmentArgs = {
  assignmentId: Scalars['UUID'];
};


export type OrderActionMutationsLaundryCancelOrderArgs = {
  reasonForRejectionId: Scalars['String'];
};


export type OrderActionMutationsLaundryCreateDeliveryArgs = {
  addressSsoId: Scalars['UUID'];
  options?: InputMaybe<ReadonlyArray<InputMaybe<CallActionOrderOptions>>>;
  startAtTimestamp: Scalars['String'];
};


export type OrderActionMutationsLaundryOrderReviewedArgs = {
  options: ReadonlyArray<LaundryOrderReviewedOptionsInput>;
  receiptLink?: InputMaybe<Scalars['String']>;
};


export type OrderActionMutationsLaundryRejectAssignmentArgs = {
  assignmentId: Scalars['UUID'];
  reasonForRejectionId: Scalars['String'];
};


export type OrderActionMutationsUpdateAssignmentArgs = {
  addressSsoId: Scalars['UUID'];
  assignmentId: Scalars['UUID'];
  options?: InputMaybe<ReadonlyArray<InputMaybe<CallActionOrderOptions>>>;
  startAtTimestamp: Scalars['String'];
};


export type OrderActionMutationsUpdateOptionsArgs = {
  options: ReadonlyArray<UpdateOptionsInput>;
};

export type OrderAddress = {
  readonly __typename: 'OrderAddress';
  readonly coordinates: OrderAddressCoordinates;
  readonly createdAt: Scalars['DateTime'];
  readonly deletedAt?: Maybe<Scalars['DateTime']>;
  readonly detail: OrderAddressDetail;
  readonly id: Scalars['UUID'];
  readonly isDeleted: Scalars['Boolean'];
  readonly metro: ReadonlyArray<OrderAddressMetro>;
  readonly updatedAt: Scalars['DateTime'];
  /** Стандартизованный адрес одной строкой */
  readonly value: Scalars['String'];
};

export type OrderAddressCoordinates = {
  readonly __typename: 'OrderAddressCoordinates';
  readonly lat: Scalars['Float'];
  readonly lng: Scalars['Float'];
};

export type OrderAddressDetail = {
  readonly __typename: 'OrderAddressDetail';
  readonly area?: Maybe<Scalars['String']>;
  readonly block?: Maybe<Scalars['String']>;
  readonly city?: Maybe<Scalars['String']>;
  readonly comment?: Maybe<Scalars['String']>;
  readonly entrance?: Maybe<Scalars['String']>;
  readonly flat?: Maybe<Scalars['String']>;
  readonly floor?: Maybe<Scalars['String']>;
  readonly house?: Maybe<Scalars['String']>;
  readonly intercom?: Maybe<Scalars['String']>;
  readonly region?: Maybe<Scalars['String']>;
  readonly street?: Maybe<Scalars['String']>;
};

export type OrderAddressMetro = {
  readonly __typename: 'OrderAddressMetro';
  /** Расстояние до станции метро в км */
  readonly distance: Scalars['Float'];
  /** Название линии станции метро */
  readonly line: Scalars['String'];
  /** Название станции метро */
  readonly name: Scalars['String'];
};

export enum OrderAddressTypeEnum {
  /** Адрес выполнения заказа */
  MAIN = 'MAIN'
}

export type OrderEdge = {
  readonly __typename: 'OrderEdge';
  readonly cursor: Scalars['String'];
  readonly node: Order;
};

export type OrderFilter = {
  /** ID корзины */
  readonly cartId?: InputMaybe<ReadonlyArray<Scalars['UUID']>>;
  /** SSO идентификатор заказчика */
  readonly customerId?: InputMaybe<ReadonlyArray<Scalars['UUID']>>;
  /** ID заказа */
  readonly id?: InputMaybe<ReadonlyArray<Scalars['UUID']>>;
  /** Серийный номер заказа */
  readonly serialNumber?: InputMaybe<ReadonlyArray<Scalars['Int']>>;
};

export type OrderHistory = {
  readonly __typename: 'OrderHistory';
  /** Дата и время изменения сущности */
  readonly createdAt: Scalars['DateTime'];
  readonly id: Scalars['ID'];
  readonly initiator?: Maybe<User>;
  /** Модификации которые были выплнены за данное обновление заказа */
  readonly modifications: ReadonlyArray<OrderModification>;
};

export type OrderModification = {
  readonly __typename: 'OrderModification';
  /**
   * Данные
   * Опции всегда будут лежать массивом - все опции которые были в итоге в заказе
   * Назначение всегда обьектом с полями которые были изменены
   */
  readonly data: Scalars['JSONObject'];
  /** Тип сущности */
  readonly entityType: OrderModificationEntityEnum;
  /** Тип модификации */
  readonly type: OrderModificationTypeEnum;
};

export enum OrderModificationEntityEnum {
  ASSIGNMENT = 'ASSIGNMENT',
  OPTION = 'OPTION'
}

export enum OrderModificationTypeEnum {
  /** Назначение может быть отменено */
  CANCELLED = 'CANCELLED',
  /** Назначение может быть создано */
  CREATED = 'CREATED',
  /** Назначение может быть обновлено, Опции могут быть обновлены */
  UPDATED = 'UPDATED'
}

export enum OrderOperationResult {
  Success = 'Success'
}

export type OrderOption = {
  readonly __typename: 'OrderOption';
  readonly createdAt: Scalars['DateTime'];
  readonly deletedAt?: Maybe<Scalars['DateTime']>;
  /** Кратность */
  readonly factor: Scalars['Int'];
  readonly id: Scalars['UUID'];
  readonly isDeleted: Scalars['Boolean'];
  readonly isFree: Scalars['Boolean'];
  /** Опция */
  readonly option: Option;
  readonly orderId: Scalars['UUID'];
  /** Итоговое время подготовки к выполнению опций в минутах */
  readonly prepDuration: Scalars['Int'];
  /** Уникальное короткое обозначение опции */
  readonly slug: Scalars['String'];
  /** Стоимость выполнения опций в копейках без скидок */
  readonly subTotalPrice: Scalars['Int'];
  /** Итоговое время выполнения опций в минутах */
  readonly totalDuration: Scalars['Int'];
  /** Итоговая стоимость выполнения опций в копейках */
  readonly totalPrice: Scalars['Int'];
  readonly updatedAt: Scalars['DateTime'];
  /** Кол-во */
  readonly value: Scalars['Int'];
};

export enum OrderPaymentStatusEnum {
  /** Сумма заморожена на карте */
  AUTHORIZED = 'AUTHORIZED',
  /** Процесс оплаты отменен */
  CANCELED = 'CANCELED',
  /** Не удалось оплатить */
  FAILED = 'FAILED',
  /** Создан процесс оплаты */
  NEW = 'NEW',
  /** Успешно оплачен */
  PAID = 'PAID'
}

export enum OrderPaymentTypeEnum {
  /** Оплата картой. Неважно клиент или исполнитель. Остальные оплаты картой станут легаси */
  CARD_ONLINE = 'CARD_ONLINE',
  /** Картой */
  CLIENT_CARD = 'CLIENT_CARD',
  /** Наличными */
  CLIENT_CASH = 'CLIENT_CASH',
  /** По ссылке в банковском приложении */
  CLIENT_SMS = 'CLIENT_SMS',
  /** Оплата картой исполнителя */
  CONTRACTOR_CARD_LINKED = 'CONTRACTOR_CARD_LINKED',
  /** Рассрочка */
  INSTALLMENT_PLAN = 'INSTALLMENT_PLAN',
  /** Оплата по терминалу */
  SOFT_POS = 'SOFT_POS'
}

export enum OrderStatusEnum {
  /** Заказ отменен */
  CANCELED = 'CANCELED',
  /** Заказ выполнен */
  DONE = 'DONE',
  /** Идет выполнение заказа */
  IN_PROGRESS = 'IN_PROGRESS',
  /** Заказ формируется. Еще не подтвержден */
  NEW = 'NEW',
  /** Заказ на согласовании */
  ON_COORDINATION = 'ON_COORDINATION',
  /** Заказ запланирован */
  TODO = 'TODO',
  /** Ожидает в ПВЗ */
  WAIT_IN_PICKPOINT = 'WAIT_IN_PICKPOINT'
}

export type OrdersConnection = {
  readonly __typename: 'OrdersConnection';
  readonly edges: ReadonlyArray<OrderEdge>;
  readonly pageInfo: PageInfo;
};

/** Информация о пагинации */
export type PageInfo = {
  readonly __typename: 'PageInfo';
  /** Указатель на конец страницы */
  readonly endCursor?: Maybe<Scalars['String']>;
  readonly hasNextPage: Scalars['Boolean'];
  readonly hasPreviousPage: Scalars['Boolean'];
  /** Указатель на начало страницы */
  readonly startCursor?: Maybe<Scalars['String']>;
};

export enum PersonStatusEnum {
  /** Находится в процессе онбординга на работу */
  CANDIDATE = 'CANDIDATE',
  /** Полноценный исполнитель */
  CONTRACTOR = 'CONTRACTOR',
  /** Промежуточный, для значения по умолчанию */
  CREATED = 'CREATED'
}

export type PhotoManagement = {
  readonly __typename: 'PhotoManagement';
  /** PhotoManagement id */
  readonly id: Scalars['ID'];
  /** PhotoManagement name */
  readonly name?: Maybe<Scalars['String']>;
  /** PhotoManagement original */
  readonly original?: Maybe<Scalars['String']>;
  /** PhotoManagement tag */
  readonly tag?: Maybe<Scalars['String']>;
  /** PhotoManagement thumb */
  readonly thumb?: Maybe<Scalars['String']>;
  /** PhotoManagement url */
  readonly url?: Maybe<Scalars['String']>;
};

export type Position = {
  readonly __typename: 'Position';
  readonly id: Scalars['ID'];
  /** Уникальный слаг позиции */
  readonly name: Scalars['String'];
  /** Человекочитаемое название позиции */
  readonly title: Scalars['String'];
};

export type PostponeTaskInput = {
  /** Причина недозвона */
  readonly cancellationReason: CancellationReasonEnum;
  /** Дополнительная информация */
  readonly comment?: InputMaybe<Scalars['String']>;
  /** id задачи */
  readonly id: Scalars['UUID'];
};

export type PriceOption = {
  readonly __typename: 'PriceOption';
  /** Линейная стоимость */
  readonly costLinear?: Maybe<Scalars['Int']>;
  /** Матрица стоимости */
  readonly costMatrixItems?: Maybe<ReadonlyArray<PriceOptionMatrixItem>>;
  /** Как меняется стоимость от кол-ва */
  readonly costMatrixPer?: Maybe<MatrixUnitType>;
  readonly createdAt: Scalars['DateTime'];
  /** Множитель по умолчанию */
  readonly defaultFactor?: Maybe<Scalars['Int']>;
  /** Кол-во по умолчанию */
  readonly defaultValue?: Maybe<Scalars['Int']>;
  /** Линейная продолжительность выполнения заказа */
  readonly durationLinear?: Maybe<Scalars['Int']>;
  /** Матрица продолжительности выполнения заказа */
  readonly durationMatrixItems?: Maybe<ReadonlyArray<PriceOptionMatrixItem>>;
  /** Как меняется продолжительность от кол-ва */
  readonly durationMatrixPer?: Maybe<MatrixUnitType>;
  readonly id: Scalars['UUID'];
  readonly isFree: Scalars['Boolean'];
  /** Максимальное кол-во */
  readonly maxValue?: Maybe<Scalars['Int']>;
  /** Минимальное кол-во */
  readonly minValue?: Maybe<Scalars['Int']>;
  /** Линейная продолжительность подготовки к заказу */
  readonly prepDurationLinear?: Maybe<Scalars['Int']>;
  /** Уникальное короткое обозначение опции */
  readonly slug: Scalars['String'];
  readonly type: PriceOptionTypeEnum;
};

export type PriceOptionMatrixItem = {
  readonly __typename: 'PriceOptionMatrixItem';
  readonly id: Scalars['UUID'];
  readonly range: Scalars['String'];
  readonly value: Scalars['Int'];
};

export enum PriceOptionTypeEnum {
  /** Базовая опция */
  BASE = 'BASE',
  /** Кросс-продажа */
  CROSS_SALE = 'CROSS_SALE',
  /** Дополнительная опция */
  OPTION = 'OPTION'
}

export type PriceProduct = {
  readonly __typename: 'PriceProduct';
  /** Ограничения на минимальное кол-во базовых опции */
  readonly baseOptionConstraints: ReadonlyArray<BaseOptionConstraint>;
  /** Минимальная стоимость заказа */
  readonly costMinBase?: Maybe<Scalars['Int']>;
  readonly createdAt: Scalars['DateTime'];
  /** Дата окончания действия тарифа */
  readonly dateEnd?: Maybe<Scalars['DateTime']>;
  /** Дата начала действия тарифа */
  readonly dateStart: Scalars['DateTime'];
  readonly deletedAt?: Maybe<Scalars['DateTime']>;
  /** Минимальная продолжительность заказа */
  readonly durationMinBase?: Maybe<Scalars['Int']>;
  readonly id: Scalars['UUID'];
  readonly isDeleted: Scalars['Boolean'];
  /** Максимальная скидка в % */
  readonly maxDiscountValue?: Maybe<Scalars['Int']>;
  /** Базовое время на подготовку */
  readonly prepDuration?: Maybe<Scalars['Int']>;
  /** Опции продукта, добавленные к заказу */
  readonly priceOptions: ReadonlyArray<PriceOption>;
  /** Регион */
  readonly regionId: Scalars['UUID'];
  /** Уникальное короткое обозначение продукта */
  readonly slug: Scalars['String'];
  /** Арендатор */
  readonly tenantId: Scalars['UUID'];
  readonly updatedAt: Scalars['DateTime'];
};

export type ProceedTaskInput = {
  readonly taskId: Scalars['UUID'];
};

export type Product = {
  readonly __typename: 'Product';
  /** ID продукта */
  readonly id: Scalars['UUID'];
  /** Все доступные опции продукта */
  readonly options: ReadonlyArray<Option>;
  /** Тариф продукта */
  readonly priceProduct: PriceProduct;
  /** Причины отказа, допустимые для этого продукта */
  readonly reasonsForRejection: ReadonlyArray<ReasonForRejection>;
  /** Группы причин для отказа, допустимые для этого продукта */
  readonly rejectionGroups: ReadonlyArray<RejectionGroup>;
  /** Человеко-читаемое описание */
  readonly shortDesc?: Maybe<Scalars['String']>;
  /** Уникальное короткое обозначение */
  readonly slug: Scalars['String'];
  /** Человеко-читаемое имя */
  readonly title: Scalars['String'];
};


export type ProductReasonsForRejectionArgs = {
  filter?: InputMaybe<ReasonForRejectionFilter>;
};

export type ProductFilter = {
  readonly slug?: InputMaybe<Scalars['String']>;
};

export type Query = {
  readonly __typename: 'Query';
  /**
   * Список активных тикетов для оператора. Все тикеты назначенные на оператора в статусах NEW и OPEN
   * Фильтрация id пользователя из токена
   * Если передан assigneeSsoId будет использован вместо id из токена
   */
  readonly activeTickets: ReadonlyArray<Ticket>;
  /** Длительность назначения на основе опций в нем */
  readonly assignmentDuration: Scalars['Int'];
  /** сгруппированные причины для блокировки/заморозки пользователя */
  readonly blockingReasonGroups: ReadonlyArray<BlockingReasonGroup>;
  /** Возвращает список корзин */
  readonly carts: ReadonlyArray<Cart>;
  /**
   * Список категорий с проблемами. По проблеме создается задача.
   * Каждый тикет имеет определенную категорию.
   * В одном тикете могут быть только задачи(проблемы) соответствующие категории тикета
   */
  readonly categories: ReadonlyArray<Category>;
  /**
   * DEPRECATED
   * Заменен на поле в User
   * Найти контрактора по userId (ssoId)
   */
  readonly contractor?: Maybe<Contractor>;
  readonly contractorsForOrder: ReadonlyArray<ContractorForOrder>;
  readonly contractorsMonolithOrdersCount: Scalars['Int'];
  /**
   * Список тикетов для страницы дашборда
   * Фильтрация id пользователя из токена
   * Если передан userId будет использован вместо id из токена
   */
  readonly dashboardTickets: ReadonlyArray<Ticket>;
  /** Возвращает список слепков изменений */
  readonly entityChanges?: Maybe<ReadonlyArray<EntityChange>>;
  /** Информация о локации по геокоординатам */
  readonly geo: Geo;
  /** Информация о списке локаций по геокоординатам */
  readonly geoBatch: ReadonlyArray<BatchGeoInfo>;
  /** Данные менеджера */
  readonly manager: Manager;
  readonly orderHistory: ReadonlyArray<OrderHistory>;
  /** Возвращает пагинированный список заказов */
  readonly ordersConnection: OrdersConnection;
  /** Список позиций контракторов */
  readonly positions: ReadonlyArray<Position>;
  /** Возвращает список продуктов */
  readonly products: ReadonlyArray<Product>;
  readonly reasons: ReasonsListResponse;
  readonly surge: ReadonlyArray<Surge>;
  /** Список заданий */
  readonly tasksConnection: TasksConnection;
  /** Список тикетов */
  readonly ticketsConnection: TicketsConnection;
  readonly timeslots: ReadonlyArray<Timeslot>;
  /** Список пользователей. Поиск осуществляется по номеру телефона ИЛИ массиву id */
  readonly usersConnection: UsersConnection;
};


export type QueryActiveTicketsArgs = {
  filter?: InputMaybe<ActiveTicketsFilter>;
};


export type QueryAssignmentDurationArgs = {
  assignmentId: Scalars['UUID'];
  orderId: Scalars['UUID'];
};


export type QueryCartsArgs = {
  cartFilter: CartFilter;
};


export type QueryContractorArgs = {
  filter: ContractorFilter;
};


export type QueryContractorsForOrderArgs = {
  input: ContractorsForOrderInput;
};


export type QueryContractorsMonolithOrdersCountArgs = {
  input: ContractorsMonolithOrdersCountInput;
};


export type QueryDashboardTicketsArgs = {
  filter?: InputMaybe<DashboardTicketsFilter>;
};


export type QueryEntityChangesArgs = {
  filter?: InputMaybe<EntityChangesFilter>;
};


export type QueryGeoArgs = {
  geoInput?: InputMaybe<GeoInput>;
};


export type QueryGeoBatchArgs = {
  batchGeoInput?: InputMaybe<ReadonlyArray<InputMaybe<BatchGeoInput>>>;
};


export type QueryManagerArgs = {
  input: FindManagerInput;
};


export type QueryOrderHistoryArgs = {
  orderId: Scalars['UUID'];
};


export type QueryOrdersConnectionArgs = {
  forwardingOptions?: InputMaybe<ForwardingOptionsInput>;
  orderFilter?: InputMaybe<OrderFilter>;
};


export type QueryProductsArgs = {
  filter?: InputMaybe<ProductFilter>;
};


export type QueryReasonsArgs = {
  reasonFilter?: InputMaybe<ReasonsListFilter>;
};


export type QuerySurgeArgs = {
  input: SurgeInput;
};


export type QueryTasksConnectionArgs = {
  taskFilter?: InputMaybe<TaskFilter>;
  taskOptions?: InputMaybe<ForwardingOptionsInput>;
};


export type QueryTicketsConnectionArgs = {
  ticketFilter?: InputMaybe<TicketFilter>;
  ticketOptions?: InputMaybe<ForwardingOptionsInput>;
};


export type QueryTimeslotsArgs = {
  input: TimeslotsInput;
};


export type QueryUsersConnectionArgs = {
  userFilter?: InputMaybe<UserFilterInput>;
  userOptions?: InputMaybe<ForwardingOptionsInput>;
};

/** Причина отказа */
export type Reason = {
  readonly __typename: 'Reason';
  readonly category: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly id: Scalars['ID'];
  /** Человекочитаемое название */
  readonly title: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

/** Фильтр по причинам отказа */
export type ReasonFilter = {
  readonly id?: InputMaybe<Scalars['UUID']>;
};

export type ReasonForRejection = {
  readonly __typename: 'ReasonForRejection';
  /** Кому доступна причина */
  readonly availableFor: ReadonlyArray<ReasonForRejectionAvailabilityEnum>;
  readonly createdAt: Scalars['DateTime'];
  readonly deletedAt?: Maybe<Scalars['DateTime']>;
  readonly description: Scalars['String'];
  /** ID причины */
  readonly id: Scalars['UUID'];
  readonly isDeleted: Scalars['Boolean'];
  /** Уникальное короткое обозначение */
  readonly slug: Scalars['String'];
  readonly tenantId: Scalars['UUID'];
  readonly title: Scalars['String'];
};

export enum ReasonForRejectionAvailabilityEnum {
  /** Исполнитель */
  CONTRACTOR = 'CONTRACTOR',
  /** Клиент */
  CUSTOMER = 'CUSTOMER',
  /** Система */
  SYSTEM = 'SYSTEM'
}

export type ReasonForRejectionFilter = {
  /** Кому доступна причина */
  readonly availableFor?: InputMaybe<ReasonForRejectionAvailabilityEnum>;
  /** Подстрока для поиска в title без учета регистра */
  readonly title?: InputMaybe<Scalars['String']>;
};

export type ReasonsListFilter = {
  readonly categories: ReadonlyArray<InputMaybe<Scalars['String']>>;
};

export type ReasonsListResponse = {
  readonly __typename: 'ReasonsListResponse';
  readonly data: ReadonlyArray<Maybe<Reason>>;
};

export type RefreshTokenInput = {
  readonly refreshToken: Scalars['String'];
};

export type RejectionGroup = {
  readonly __typename: 'RejectionGroup';
  readonly createdAt: Scalars['DateTime'];
  readonly deletedAt?: Maybe<Scalars['DateTime']>;
  /** ID группы */
  readonly id: Scalars['UUID'];
  readonly isDeleted: Scalars['Boolean'];
  /** ID родительской группы */
  readonly parentGroupId?: Maybe<Scalars['String']>;
  /** Причины, входящие в группы */
  readonly reasons: ReadonlyArray<Maybe<ReasonForRejection>>;
  /** Название группы */
  readonly title: Scalars['String'];
  /** Тип группы */
  readonly type: RejectionGroupTypeEnum;
};

export enum RejectionGroupTypeEnum {
  /** Перед приездом */
  BEFORE_EXECUTE = 'BEFORE_EXECUTE',
  /** Отмена клиентом */
  BY_CLIENT = 'BY_CLIENT',
  /** Отмена контрактором */
  BY_CONTRACTOR = 'BY_CONTRACTOR',
  /** Отмена сервисом */
  BY_SERVICE = 'BY_SERVICE',
  /** Отмена технологом */
  BY_TECHNOLOGIST = 'BY_TECHNOLOGIST',
  /** Общие причины */
  DEFAULT = 'DEFAULT',
  /** Не согласны с условиями */
  NOT_SATISFIED_WITH_CONDITIONS = 'NOT_SATISFIED_WITH_CONDITIONS',
  /** При подтверждении */
  ON_CONFIRM = 'ON_CONFIRM',
  /** По приезде */
  ON_EXECUTING = 'ON_EXECUTING'
}

export type RemoveCartInput = {
  /** ID корзины */
  readonly cartId: Scalars['UUID'];
};

export type RescheduleTaskInput = {
  /** Дополнительная информация */
  readonly comment?: InputMaybe<Scalars['String']>;
  /** id задачи */
  readonly id: Scalars['UUID'];
  /** планируемое время перезвона */
  readonly to: Scalars['DateTime'];
};

export type RevocationPersonalDataInput = {
  readonly id: Scalars['ID'];
};

export type RunningOperation = {
  readonly __typename: 'RunningOperation';
  readonly correlationId: Scalars['UUID'];
};

export type SagaResult = {
  readonly __typename: 'SagaResult';
  readonly correlationId: Scalars['UUID'];
  readonly error?: Maybe<SagaResultError>;
  readonly result: SagaResultEnum;
};

export enum SagaResultEnum {
  FAILURE = 'FAILURE',
  SUCCESSFUL = 'SUCCESSFUL'
}

export type SagaResultError = {
  readonly __typename: 'SagaResultError';
  readonly message: Scalars['String'];
};

export type Skill = {
  readonly __typename: 'Skill';
  readonly createdAt: Scalars['DateTime'];
  readonly deletedAt?: Maybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly isDeleted: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly priority: Scalars['Int'];
  readonly updatedAt: Scalars['DateTime'];
};

export type SubCategory = {
  readonly __typename: 'SubCategory';
  readonly id: Scalars['ID'];
  /**
   * Скилл соответствующий данному типу.
   * Решить задачу созданную по этой подкатегории сможет менеджер, у которого есть данный скилл.
   */
  readonly skill: Scalars['String'];
  readonly title: Scalars['String'];
};

export type Subscription = {
  readonly __typename: 'Subscription';
  readonly sagaFinished: SagaResult;
  readonly taskChanged: Task;
  readonly ticketChanged: Ticket;
};


export type SubscriptionTaskChangedArgs = {
  filter?: InputMaybe<TaskChangedFilter>;
};


export type SubscriptionTicketChangedArgs = {
  filter?: InputMaybe<TicketChangedFilter>;
};

export type Surge = {
  readonly __typename: 'Surge';
  readonly coefficient: Scalars['Float'];
  readonly dateEnd: Scalars['DateTime'];
  readonly dateStart: Scalars['DateTime'];
  readonly element: Scalars['String'];
  readonly regionId: Scalars['Int'];
};

export type SurgeInput = {
  /** Дата окончания периода для поиска. Меньше или равно */
  readonly dateEnd?: InputMaybe<Scalars['DateTime']>;
  /** Дата начала периода для поиска. Больше или равно. Нельзя запрашивать раньше чем за текущий год. По умолчанию берется начало текущего месяца */
  readonly dateStart?: InputMaybe<Scalars['DateTime']>;
  /** слаг продукта */
  readonly element: ReadonlyArray<Scalars['String']>;
  /** Легаси id региона (1 или 2) */
  readonly regionId: Scalars['Int'];
};

export type Task = {
  readonly __typename: 'Task';
  /** Причина по которой задаче отменена. Если задача не была отменена, полем будет null */
  readonly cancellationReason?: Maybe<Reason>;
  /** ssoId клиента */
  readonly clientId: Scalars['UUID'];
  readonly comment?: Maybe<Scalars['String']>;
  readonly createdAt: Scalars['DateTime'];
  readonly deletedAt?: Maybe<Scalars['DateTime']>;
  readonly entity?: Maybe<TaskEntity>;
  readonly executionHistory?: Maybe<ReadonlyArray<ExecutionHistoryItem>>;
  readonly id: Scalars['ID'];
  readonly isDeleted: Scalars['Boolean'];
  /** Дата последнего недозвона */
  readonly lastMissedCallDate?: Maybe<Scalars['DateTime']>;
  /** Менеджер к которому задача привязана в данный момент. Является исполнителем задачи */
  readonly owner?: Maybe<Manager>;
  /**
   * Дата на которую перенесена задача
   * @deprecated Не используется
   */
  readonly postponedTo?: Maybe<Scalars['DateTime']>;
  readonly regionId?: Maybe<Scalars['UUID']>;
  readonly skill: Skill;
  readonly state: TaskStateEnum;
  readonly title: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};

export type TaskChangedFilter = {
  readonly ownerId?: InputMaybe<Scalars['UUID']>;
};

export type TaskCreateInput = {
  /** SSO id клиента */
  readonly clientId: Scalars['UUID'];
  readonly comment?: InputMaybe<Scalars['String']>;
  /** Связанная с задачей сущность */
  readonly entity?: InputMaybe<LinkedTaskEntityInput>;
  /** SSO id владельца задачи */
  readonly managerSsoId?: InputMaybe<Scalars['UUID']>;
  readonly regionId?: InputMaybe<Scalars['UUID']>;
  readonly skill: Scalars['String'];
  readonly title: Scalars['String'];
};

/** Узёл TasksConnection */
export type TaskEdge = {
  readonly __typename: 'TaskEdge';
  /** Указатель на элемент в списке */
  readonly cursor: Scalars['String'];
  readonly node: Task;
};

/** Сущность по которой создана задача */
export type TaskEntity = {
  readonly __typename: 'TaskEntity';
  readonly entityId: Scalars['ID'];
  /** Тип сущности */
  readonly entityType: EntityTypeEnum;
};

export type TaskFilter = {
  /** SSO id клиента */
  readonly clientId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']>>>;
  /** Идентификатор связанной сущности */
  readonly entityId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']>>>;
  /** Тип сущности связанной с задачей */
  readonly entityType?: InputMaybe<ReadonlyArray<InputMaybe<EntityTypeEnum>>>;
  readonly id?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']>>>;
  /** Состояние задачи */
  readonly state?: InputMaybe<ReadonlyArray<InputMaybe<TaskStateEnum>>>;
};

export enum TaskStateEnum {
  /** Задача отменена. Не будет сделана */
  CANCELLED = 'CANCELLED',
  /** Дубликат. Не требуется обработка */
  DEDUPLICATED = 'DEDUPLICATED',
  /** Задача взята в работу */
  IN_WORK = 'IN_WORK',
  /** Задача создана */
  NEW = 'NEW',
  /** Недозвон */
  NO_COMMUNICATION = 'NO_COMMUNICATION',
  /** Задача отложена */
  POSTPONED = 'POSTPONED',
  /** Задача сделана. Других действий по ней больше не требуется */
  PROCEED = 'PROCEED',
  /** Задача перенесена в перезвон. Нужно перезвонить в определенное время */
  RESCHEDULED = 'RESCHEDULED'
}

/** Список заданий для relay пагинации */
export type TasksConnection = {
  readonly __typename: 'TasksConnection';
  /** Массив заданий */
  readonly edges: ReadonlyArray<TaskEdge>;
  readonly pageInfo: PageInfo;
};

export type Ticket = {
  readonly __typename: 'Ticket';
  /** ssoId пользователя назначенного как исполнитель */
  readonly assigneeSsoId?: Maybe<Scalars['UUID']>;
  readonly assigneeSsoProfile: User;
  readonly category: TicketCategory;
  readonly clientSsoId: Scalars['UUID'];
  readonly clientSsoProfile: User;
  readonly content: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  /** ssoId пользователя создавшего тикет. Если тикет создан автоматически, записывается id пользователя инициировавший создание тикета */
  readonly creatorSsoId: Scalars['UUID'];
  readonly id: Scalars['ID'];
  /** Сущность над которой ведется работа в данном тикете. На текущий момент это только Order */
  readonly links?: Maybe<ReadonlyArray<Link>>;
  /** Номер тикета, генерится автоматически на backend */
  readonly number: Scalars['Int'];
  readonly state: TicketStateEnum;
  readonly tasks: TasksConnection;
  /** Информация для однозначного соответствия сущности над которой ведется работа в тикете с задачами тикета. Например: Тикет привязан к корзине. В этом случае задачи будут соответствовать заказам из этой корзины */
  readonly ticketTasks?: Maybe<ReadonlyArray<TicketTask>>;
  readonly title: Scalars['String'];
  readonly updatedAt: Scalars['DateTime'];
};


export type TicketTasksArgs = {
  taskOptions?: InputMaybe<ForwardingOptionsInput>;
};

/** Тип для категории в конкретном тикете. Через этот тип нельзя получить весь набор проблем, который есть в категории */
export type TicketCategory = {
  readonly __typename: 'TicketCategory';
  readonly id: Scalars['ID'];
  readonly title: Scalars['String'];
  readonly type: CategoryTypeEnum;
};

export type TicketChangedFilter = {
  readonly assigneeSsoId?: InputMaybe<Scalars['UUID']>;
};

export type TicketCreateInput = {
  readonly categoryId: Scalars['UUID'];
  readonly clientSsoId: Scalars['UUID'];
  readonly content?: InputMaybe<Scalars['String']>;
  readonly creatorSsoId: Scalars['UUID'];
  readonly title?: InputMaybe<Scalars['String']>;
};

export type TicketEdge = {
  readonly __typename: 'TicketEdge';
  readonly cursor: Scalars['String'];
  readonly node: Ticket;
};

export type TicketFilter = {
  readonly assigneeSsoId?: InputMaybe<ReadonlyArray<Scalars['UUID']>>;
  readonly clientSsoId?: InputMaybe<ReadonlyArray<Scalars['UUID']>>;
  readonly creatorSsoId?: InputMaybe<ReadonlyArray<Scalars['UUID']>>;
  readonly id?: InputMaybe<ReadonlyArray<Scalars['UUID']>>;
  readonly state?: InputMaybe<ReadonlyArray<TicketStateEnum>>;
  readonly ticketLink?: InputMaybe<TicketLinkFilter>;
};

export type TicketLinkFilter = {
  /** Номер заказа */
  readonly serialNumber?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
};

export enum TicketStateEnum {
  /** Тикет закрыт. Не решен */
  CLOSED = 'CLOSED',
  /** Тикет обработан, в ожидании */
  HOLD = 'HOLD',
  /** Новый тикет, никто не обработал */
  NEW = 'NEW',
  /** Тикет взять в работу */
  OPENED = 'OPENED',
  /** Тикет решен успешно */
  SOLVED = 'SOLVED'
}

export type TicketTask = {
  readonly __typename: 'TicketTask';
  readonly linkedEntity: LinkedEntity;
  readonly taskId: Scalars['UUID'];
};

export type TicketsConnection = {
  readonly __typename: 'TicketsConnection';
  readonly edges: ReadonlyArray<TicketEdge>;
  readonly pageInfo: PageInfo;
};

export type Timeslot = {
  readonly __typename: 'Timeslot';
  readonly available: Scalars['Boolean'];
  readonly endAt: Scalars['Time'];
  readonly startAt: Scalars['Time'];
};

export type TimeslotsInput = {
  readonly latitude: Scalars['Float'];
  readonly longitude: Scalars['Float'];
  readonly serviceSlug: Scalars['String'];
};

export type UpdateEntityChangeInput = {
  readonly data?: InputMaybe<Scalars['JSONObject']>;
  readonly expirationDate?: InputMaybe<Scalars['DateTime']>;
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateEntityChangeWhere = {
  readonly id: Scalars['UUID'];
};

export type UpdateOptionsInput = {
  /** Является ли опция бесплатной */
  readonly isFree?: InputMaybe<Scalars['Boolean']>;
  /** Слаг опции */
  readonly slug: Scalars['String'];
  /** Кол-во */
  readonly value: Scalars['Int'];
};

export type UpdateOrderAddressInput = {
  /** ID адреса в SSO */
  readonly addressId?: InputMaybe<Scalars['UUID']>;
  /** ID заказа */
  readonly orderId: Scalars['UUID'];
  /** Тип адреса */
  readonly type: OrderAddressTypeEnum;
};

export type UpdateOrderAssignmentStrategyInput = {
  /** ID стратегии назначений */
  readonly assignmentStrategyId?: InputMaybe<Scalars['UUID']>;
  /** список из ID пользователей исполнителей в SSO */
  readonly contractors?: InputMaybe<ReadonlyArray<Scalars['UUID']>>;
  /** ID заказа */
  readonly orderId: Scalars['UUID'];
};

export type UpdateOrderInput = {
  /** Комментарий для исполнителя */
  readonly commentForContractor?: InputMaybe<Scalars['String']>;
  /** ID пользователя заказчика в SSO */
  readonly customerId?: InputMaybe<Scalars['String']>;
  /** ID заказа */
  readonly orderId: Scalars['UUID'];
  /** Способ оплаты */
  readonly paymentType?: InputMaybe<OrderPaymentTypeEnum>;
  /** Доп инфа для способа оплаты. Например для оплаты картой, в мете хранится айди карты в финансах */
  readonly paymentTypeMeta?: InputMaybe<Scalars['String']>;
  /** Промокод */
  readonly promocode?: InputMaybe<Scalars['String']>;
  /** Время начала выполнения заказа */
  readonly startDate?: InputMaybe<Scalars['DateTime']>;
  /** Использовать ли бонусы в заказе, если значение = true, бонусы будут применены (попытка применить) в момент подтверждения заказа */
  readonly useBonuses?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateTaskInput = {
  /** комментарий */
  readonly comment?: InputMaybe<Scalars['String']>;
  /** id задачи */
  readonly id: Scalars['ID'];
};

export type UpdateUserAddressInput = {
  readonly coordinates?: InputMaybe<CoordinatesInput>;
  readonly detail?: InputMaybe<AddressDetailInput>;
  readonly id: Scalars['ID'];
  readonly isDeleted?: InputMaybe<Scalars['Boolean']>;
  readonly label?: InputMaybe<Scalars['String']>;
  readonly metro?: InputMaybe<ReadonlyArray<MetroInput>>;
  readonly object?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<AddressTypeEnum>;
  readonly unrestrictedValue?: InputMaybe<Scalars['String']>;
  readonly value?: InputMaybe<Scalars['String']>;
};

export type UpdateUserPhotoManagementInput = {
  readonly id: Scalars['ID'];
  readonly name?: InputMaybe<Scalars['String']>;
  readonly original?: InputMaybe<Scalars['String']>;
  readonly tag?: InputMaybe<Scalars['String']>;
  readonly thumb?: InputMaybe<Scalars['String']>;
  readonly url: Scalars['String'];
};

export type UpdateUserProfileInfoInput = {
  readonly birthday?: InputMaybe<Scalars['String']>;
  readonly firstName?: InputMaybe<Scalars['String']>;
  readonly gender?: InputMaybe<GenderTypeEnum>;
  readonly id: Scalars['ID'];
  readonly isValid?: InputMaybe<Scalars['Boolean']>;
  readonly isVerified?: InputMaybe<Scalars['Boolean']>;
  readonly lastName?: InputMaybe<Scalars['String']>;
  readonly middleName?: InputMaybe<Scalars['String']>;
  readonly object?: InputMaybe<DadataFioInput>;
};

export type UpsertOrderOptionInput = {
  /** Является ли опция бесплатной */
  readonly isFree?: InputMaybe<Scalars['Boolean']>;
  /** ID опции */
  readonly optionId: Scalars['UUID'];
  /** ID заказа */
  readonly orderId: Scalars['UUID'];
  /** Кол-во */
  readonly value?: InputMaybe<Scalars['Int']>;
};

export type UpsertSurgeInput = {
  readonly coefficient: Scalars['Float'];
  readonly dateEnd: Scalars['DateTime'];
  /** Только от текущей даты, нельзя менять сурджи задним числом */
  readonly dateStart: Scalars['DateTime'];
  readonly element: ReadonlyArray<Scalars['String']>;
  readonly regionId: ReadonlyArray<Scalars['Int']>;
};

/** Клиент. Резолвится на стороне sso, в тикет менеджере возвращаем id из clientSsoId */
export type User = {
  readonly __typename: 'User';
  /** User accessBlockIsTemp */
  readonly accessBlockIsTemp?: Maybe<Scalars['Boolean']>;
  /** User accessBlockedAt */
  readonly accessBlockedAt?: Maybe<Scalars['DateTime']>;
  /** User accessBlockingType */
  readonly accessBlockingType?: Maybe<AccessBlockingTypeEnum>;
  /** User addresses */
  readonly addresses: ReadonlyArray<Address>;
  /** User birthday */
  readonly birthday?: Maybe<Scalars['String']>;
  /** User blockedAt */
  readonly blockedAt?: Maybe<Scalars['DateTime']>;
  /** User blockingReasonDescription */
  readonly blockingReasonDescription?: Maybe<Scalars['String']>;
  /** User blockingReasonSlug */
  readonly blockingReasonSlug?: Maybe<Scalars['String']>;
  /** User contacts */
  readonly contacts: ReadonlyArray<Contact>;
  /** Контрактор существует только у пользователей с типом - Contractor */
  readonly contractor?: Maybe<Contractor>;
  /** created date */
  readonly createdAt: Scalars['DateTime'];
  /** User firstName */
  readonly firstName?: Maybe<Scalars['String']>;
  /** User gender */
  readonly gender?: Maybe<GenderTypeEnum>;
  /** User id */
  readonly id: Scalars['ID'];
  /** User isBlocked */
  readonly isBlocked: Scalars['Boolean'];
  /** is deleted flag */
  readonly isDeleted: Scalars['Boolean'];
  /** User isVerified */
  readonly isVerified: Scalars['Boolean'];
  /** User lastName */
  readonly lastName?: Maybe<Scalars['String']>;
  /** User middleName */
  readonly middleName?: Maybe<Scalars['String']>;
  /** dadata object */
  readonly object?: Maybe<DadataFio>;
  /** User photos */
  readonly photos: ReadonlyArray<PhotoManagement>;
  /** Арендатор */
  readonly tenant?: Maybe<UserTenant>;
  /** User userType */
  readonly userType: UserTypeEnum;
};


/** Клиент. Резолвится на стороне sso, в тикет менеджере возвращаем id из clientSsoId */
export type UserAddressesArgs = {
  input?: InputMaybe<UserAddressesInput>;
};

export type UserAddressesInput = {
  readonly id?: InputMaybe<Scalars['String']>;
  readonly type?: InputMaybe<AddressTypeEnum>;
};

export type UserEdge = {
  readonly __typename: 'UserEdge';
  readonly cursor: Scalars['String'];
  readonly node: User;
};

export type UserFilterInput = {
  /** Id профиля */
  readonly id?: InputMaybe<ReadonlyArray<Scalars['UUID']>>;
  /**
   * Поиск по имени.
   * Поиск будет осуществляться по Имени и Фамилии.
   * Можно вводить имя или фамилию по отдельности, можно так же имя и фамилию вместе в любом порядке.
   *
   * Например:
   *
   * Зина Иванова и Иванова Зина - одинаковый запрос
   *
   * Поиск по имени Зина будет включать всех у кого в имени или фамилии есть подслово зина
   */
  readonly name?: InputMaybe<Scalars['String']>;
  /** Частично введенный номер телефона, вводится от начала всегда */
  readonly phone?: InputMaybe<Scalars['String']>;
  /** userType */
  readonly userType?: InputMaybe<ReadonlyArray<UserTypeEnum>>;
};

export type UserTenant = {
  readonly __typename: 'UserTenant';
  readonly id: Scalars['ID'];
  /** Уникальное имя арендатора */
  readonly name: Scalars['String'];
};

export enum UserTypeEnum {
  CONTRACTOR = 'CONTRACTOR',
  CUSTOMER = 'CUSTOMER',
  EMPLOYEE = 'EMPLOYEE',
  PARTNER = 'PARTNER'
}

export type UsersConnection = {
  readonly __typename: 'UsersConnection';
  readonly edges: ReadonlyArray<UserEdge>;
  readonly pageInfo: PageInfo;
};

export type Waypoint = {
  readonly __typename: 'Waypoint';
  readonly address: Address;
  readonly id: Scalars['UUID'];
  readonly items: ReadonlyArray<Maybe<CargoItem>>;
  readonly timeslot: Timeslot;
  readonly type: WaypointTypeEnum;
};

export enum WaypointTypeEnum {
  DELIVERY = 'DELIVERY',
  PICKUP = 'PICKUP',
  UNKNOWN = 'UNKNOWN'
}

export enum WorkStatusEnum {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  FROZEN = 'FROZEN'
}
