
/* eslint-disable */
import { AbstractClientBase, GrpcWebClientBase, Metadata, Error, ClientReadableStream } from 'grpc-web';
import { PlatformAdminApi } from './codegen/app_pb';

type Options = {
  ignoreInterceptors?: boolean
}

export class GrpcService {
  private client: GrpcWebClientBase;
  private metadata: Metadata = {};
  private hostname: string;
  private interceptingPromise?: Promise<any>;
  public interceptors: { errors: ((e: any) => Promise<any>)[] } = {
    errors: []
  };
  constructor(hostname: string) {
    this.client = new GrpcWebClientBase({});
    this.hostname = hostname;
  }
  private makeInterceptedUnaryCall = <Result, Params, MethodInfo>(command: string, params: Params, methodInfo: MethodInfo, options: Options = {}): Promise<Result> => {
    const unaryCallHandler = (): Promise<Result> => this.client.unaryCall(command, params, this.metadata, methodInfo)
    
    if (options.ignoreInterceptors) {
      return unaryCallHandler()
    }
    
    if (this.interceptingPromise) {
      return this.interceptingPromise.then(() => {
        this.interceptingPromise = undefined;
        return unaryCallHandler()
      });
    }
    
    return new Promise((resolve, reject) => {
      unaryCallHandler().then(resolve).catch(e => {
        this.chainingInterceptors(this.interceptors.errors, e).then(() => {
          this.makeInterceptedUnaryCall<Result, Params, MethodInfo>(command, params, methodInfo).then(resolve).catch(reject)
        }).catch(reject)
      });
    });
  }
  private chainingInterceptors = (interceptors: ((e: any) => Promise<any>)[], value: any) => {
    this.interceptingPromise = interceptors.reduce(
      (chain, nextInterceptor) => chain.then(nextInterceptor),
      Promise.resolve(value)
    );
    return this.interceptingPromise;
  }
  public setMetadata = (metadata: Metadata = {}) => {
    this.metadata = Object.assign({}, this.metadata, metadata);
  };
  public AuthorizeService = {
    SendEmailAndPass: (params: PlatformAdminApi.ISendEmailAndPassRequest, options: Options = {}): Promise<PlatformAdminApi.TokenResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TokenResponse,
        (request: PlatformAdminApi.SendEmailAndPassRequest) => PlatformAdminApi.SendEmailAndPassRequest.encode(request).finish(),
        PlatformAdminApi.TokenResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.AuthorizeService/SendEmailAndPass', params, methodInfo, options);
    },
    RefreshToken: (params: PlatformAdminApi.IRefreshTokenRequest, options: Options = {}): Promise<PlatformAdminApi.TokenResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TokenResponse,
        (request: PlatformAdminApi.RefreshTokenRequest) => PlatformAdminApi.RefreshTokenRequest.encode(request).finish(),
        PlatformAdminApi.TokenResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.AuthorizeService/RefreshToken', params, methodInfo, options);
    },
  };
  public GalleryService = {
    SingleUpload: (params: PlatformAdminApi.IGallerySingleUploadRequest, options: Options = {}): Promise<PlatformAdminApi.GallerySingleUploadResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.GallerySingleUploadResponse,
        (request: PlatformAdminApi.GallerySingleUploadRequest) => PlatformAdminApi.GallerySingleUploadRequest.encode(request).finish(),
        PlatformAdminApi.GallerySingleUploadResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.GalleryService/SingleUpload', params, methodInfo, options);
    },
    MultiUpload: (params: PlatformAdminApi.IGalleryMultiUploadRequest, options: Options = {}): Promise<PlatformAdminApi.GalleryMultiUploadResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.GalleryMultiUploadResponse,
        (request: PlatformAdminApi.GalleryMultiUploadRequest) => PlatformAdminApi.GalleryMultiUploadRequest.encode(request).finish(),
        PlatformAdminApi.GalleryMultiUploadResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.GalleryService/MultiUpload', params, methodInfo, options);
    },
  };
  public PresencePolygonService = {
    Create: (params: PlatformAdminApi.ICreatePresencePolygonRequest, options: Options = {}): Promise<PlatformAdminApi.PresencePolygon> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PresencePolygon,
        (request: PlatformAdminApi.CreatePresencePolygonRequest) => PlatformAdminApi.CreatePresencePolygonRequest.encode(request).finish(),
        PlatformAdminApi.PresencePolygon.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PresencePolygonService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IUpdatePresencePolygonRequest, options: Options = {}): Promise<PlatformAdminApi.PresencePolygon> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PresencePolygon,
        (request: PlatformAdminApi.UpdatePresencePolygonRequest) => PlatformAdminApi.UpdatePresencePolygonRequest.encode(request).finish(),
        PlatformAdminApi.PresencePolygon.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PresencePolygonService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.ISearchPresencePolygonRequest, options: Options = {}): Promise<PlatformAdminApi.PresencePolygonsList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PresencePolygonsList,
        (request: PlatformAdminApi.SearchPresencePolygonRequest) => PlatformAdminApi.SearchPresencePolygonRequest.encode(request).finish(),
        PlatformAdminApi.PresencePolygonsList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PresencePolygonService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.PresencePolygon> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PresencePolygon,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.PresencePolygon.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PresencePolygonService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.PresencePolygon> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PresencePolygon,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.PresencePolygon.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PresencePolygonService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.PresencePolygon> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PresencePolygon,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.PresencePolygon.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PresencePolygonService/Restore', params, methodInfo, options);
    },
  };
  public PositionService = {
    Create: (params: PlatformAdminApi.IPositionCreateRequest, options: Options = {}): Promise<PlatformAdminApi.PositionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PositionResponse,
        (request: PlatformAdminApi.PositionCreateRequest) => PlatformAdminApi.PositionCreateRequest.encode(request).finish(),
        PlatformAdminApi.PositionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PositionService/Create', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.IPositionSearchRequest, options: Options = {}): Promise<PlatformAdminApi.PositionSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PositionSearchResponse,
        (request: PlatformAdminApi.PositionSearchRequest) => PlatformAdminApi.PositionSearchRequest.encode(request).finish(),
        PlatformAdminApi.PositionSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PositionService/Search', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IPositionUpdateRequest, options: Options = {}): Promise<PlatformAdminApi.PositionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PositionResponse,
        (request: PlatformAdminApi.PositionUpdateRequest) => PlatformAdminApi.PositionUpdateRequest.encode(request).finish(),
        PlatformAdminApi.PositionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PositionService/Update', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.PositionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PositionResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.PositionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PositionService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IPositionRemoveRequest, options: Options = {}): Promise<PlatformAdminApi.PositionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PositionResponse,
        (request: PlatformAdminApi.PositionRemoveRequest) => PlatformAdminApi.PositionRemoveRequest.encode(request).finish(),
        PlatformAdminApi.PositionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PositionService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IPositionRestoreRequest, options: Options = {}): Promise<PlatformAdminApi.PositionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PositionResponse,
        (request: PlatformAdminApi.PositionRestoreRequest) => PlatformAdminApi.PositionRestoreRequest.encode(request).finish(),
        PlatformAdminApi.PositionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PositionService/Restore', params, methodInfo, options);
    },
  };
  public SpecializationsService = {
    Search: (params: PlatformAdminApi.ISpecializationsSearchRequest, options: Options = {}): Promise<PlatformAdminApi.SpecializationsSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SpecializationsSearchResponse,
        (request: PlatformAdminApi.SpecializationsSearchRequest) => PlatformAdminApi.SpecializationsSearchRequest.encode(request).finish(),
        PlatformAdminApi.SpecializationsSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.SpecializationsService/Search', params, methodInfo, options);
    },
    Create: (params: PlatformAdminApi.ISpecializationCreateRequest, options: Options = {}): Promise<PlatformAdminApi.SpecializationResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SpecializationResponse,
        (request: PlatformAdminApi.SpecializationCreateRequest) => PlatformAdminApi.SpecializationCreateRequest.encode(request).finish(),
        PlatformAdminApi.SpecializationResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.SpecializationsService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.ISpecializationUpdateRequest, options: Options = {}): Promise<PlatformAdminApi.SpecializationResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SpecializationResponse,
        (request: PlatformAdminApi.SpecializationUpdateRequest) => PlatformAdminApi.SpecializationUpdateRequest.encode(request).finish(),
        PlatformAdminApi.SpecializationResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.SpecializationsService/Update', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.SpecializationResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SpecializationResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.SpecializationResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.SpecializationsService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.ISpecializationRemoveRequest, options: Options = {}): Promise<PlatformAdminApi.SpecializationResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SpecializationResponse,
        (request: PlatformAdminApi.SpecializationRemoveRequest) => PlatformAdminApi.SpecializationRemoveRequest.encode(request).finish(),
        PlatformAdminApi.SpecializationResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.SpecializationsService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.ISpecializationRestoreRequest, options: Options = {}): Promise<PlatformAdminApi.SpecializationResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SpecializationResponse,
        (request: PlatformAdminApi.SpecializationRestoreRequest) => PlatformAdminApi.SpecializationRestoreRequest.encode(request).finish(),
        PlatformAdminApi.SpecializationResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.SpecializationsService/Restore', params, methodInfo, options);
    },
  };
  public BlockingReasonGroupService = {
    Search: (params: PlatformAdminApi.IBlockingReasonGroupSearchRequest, options: Options = {}): Promise<PlatformAdminApi.BlockingReasonGroupSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.BlockingReasonGroupSearchResponse,
        (request: PlatformAdminApi.BlockingReasonGroupSearchRequest) => PlatformAdminApi.BlockingReasonGroupSearchRequest.encode(request).finish(),
        PlatformAdminApi.BlockingReasonGroupSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.BlockingReasonGroupService/Search', params, methodInfo, options);
    },
    Create: (params: PlatformAdminApi.IBlockingReasonGroupCreateRequest, options: Options = {}): Promise<PlatformAdminApi.BlockingReasonGroupResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.BlockingReasonGroupResponse,
        (request: PlatformAdminApi.BlockingReasonGroupCreateRequest) => PlatformAdminApi.BlockingReasonGroupCreateRequest.encode(request).finish(),
        PlatformAdminApi.BlockingReasonGroupResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.BlockingReasonGroupService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IBlockingReasonGroupUpdateRequest, options: Options = {}): Promise<PlatformAdminApi.BlockingReasonGroupResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.BlockingReasonGroupResponse,
        (request: PlatformAdminApi.BlockingReasonGroupUpdateRequest) => PlatformAdminApi.BlockingReasonGroupUpdateRequest.encode(request).finish(),
        PlatformAdminApi.BlockingReasonGroupResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.BlockingReasonGroupService/Update', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.BlockingReasonGroupResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.BlockingReasonGroupResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.BlockingReasonGroupResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.BlockingReasonGroupService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IBlockingReasonGroupRemoveRequest, options: Options = {}): Promise<PlatformAdminApi.BlockingReasonGroupResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.BlockingReasonGroupResponse,
        (request: PlatformAdminApi.BlockingReasonGroupRemoveRequest) => PlatformAdminApi.BlockingReasonGroupRemoveRequest.encode(request).finish(),
        PlatformAdminApi.BlockingReasonGroupResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.BlockingReasonGroupService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IBlockingReasonGroupRestoreRequest, options: Options = {}): Promise<PlatformAdminApi.BlockingReasonGroupResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.BlockingReasonGroupResponse,
        (request: PlatformAdminApi.BlockingReasonGroupRestoreRequest) => PlatformAdminApi.BlockingReasonGroupRestoreRequest.encode(request).finish(),
        PlatformAdminApi.BlockingReasonGroupResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.BlockingReasonGroupService/Restore', params, methodInfo, options);
    },
  };
  public SkillService = {
    Create: (params: PlatformAdminApi.ISkillCreateRequest, options: Options = {}): Promise<PlatformAdminApi.SkillResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SkillResponse,
        (request: PlatformAdminApi.SkillCreateRequest) => PlatformAdminApi.SkillCreateRequest.encode(request).finish(),
        PlatformAdminApi.SkillResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.SkillService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.ISkillUpdateRequest, options: Options = {}): Promise<PlatformAdminApi.SkillResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SkillResponse,
        (request: PlatformAdminApi.SkillUpdateRequest) => PlatformAdminApi.SkillUpdateRequest.encode(request).finish(),
        PlatformAdminApi.SkillResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.SkillService/Update', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.SkillResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SkillResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.SkillResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.SkillService/FindById', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.ISkillSearchRequest, options: Options = {}): Promise<PlatformAdminApi.SkillSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SkillSearchResponse,
        (request: PlatformAdminApi.SkillSearchRequest) => PlatformAdminApi.SkillSearchRequest.encode(request).finish(),
        PlatformAdminApi.SkillSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.SkillService/Search', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.ISkillRemoveRequest, options: Options = {}): Promise<PlatformAdminApi.SkillResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SkillResponse,
        (request: PlatformAdminApi.SkillRemoveRequest) => PlatformAdminApi.SkillRemoveRequest.encode(request).finish(),
        PlatformAdminApi.SkillResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.SkillService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.ISkillRemoveRequest, options: Options = {}): Promise<PlatformAdminApi.SkillResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SkillResponse,
        (request: PlatformAdminApi.SkillRemoveRequest) => PlatformAdminApi.SkillRemoveRequest.encode(request).finish(),
        PlatformAdminApi.SkillResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.SkillService/Restore', params, methodInfo, options);
    },
  };
  public WageService = {
    Create: (params: PlatformAdminApi.IWageCreatePage, options: Options = {}): Promise<PlatformAdminApi.WageResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.WageResponse,
        (request: PlatformAdminApi.WageCreatePage) => PlatformAdminApi.WageCreatePage.encode(request).finish(),
        PlatformAdminApi.WageResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.WageService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IWageEditPage, options: Options = {}): Promise<PlatformAdminApi.WageResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.WageResponse,
        (request: PlatformAdminApi.WageEditPage) => PlatformAdminApi.WageEditPage.encode(request).finish(),
        PlatformAdminApi.WageResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.WageService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.IWageSearchRequest, options: Options = {}): Promise<PlatformAdminApi.WagesList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.WagesList,
        (request: PlatformAdminApi.WageSearchRequest) => PlatformAdminApi.WageSearchRequest.encode(request).finish(),
        PlatformAdminApi.WagesList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.WageService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.WageViewResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.WageViewResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.WageViewResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.WageService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.WageViewResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.WageViewResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.WageViewResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.WageService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.WageViewResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.WageViewResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.WageViewResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.WageService/Restore', params, methodInfo, options);
    },
    RetrieveEditWage: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.WageEditPage> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.WageEditPage,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.WageEditPage.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.WageService/RetrieveEditWage', params, methodInfo, options);
    },
    SuggestSpecializations: (params: PlatformAdminApi.IWageSuggestSpecializationsRequest, options: Options = {}): Promise<PlatformAdminApi.WageSuggestSpecializationsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.WageSuggestSpecializationsResponse,
        (request: PlatformAdminApi.WageSuggestSpecializationsRequest) => PlatformAdminApi.WageSuggestSpecializationsRequest.encode(request).finish(),
        PlatformAdminApi.WageSuggestSpecializationsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.WageService/SuggestSpecializations', params, methodInfo, options);
    },
    SuggestRegulations: (params: PlatformAdminApi.IWageSuggestRegulationsRequest, options: Options = {}): Promise<PlatformAdminApi.WageSuggestRegulationsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.WageSuggestRegulationsResponse,
        (request: PlatformAdminApi.WageSuggestRegulationsRequest) => PlatformAdminApi.WageSuggestRegulationsRequest.encode(request).finish(),
        PlatformAdminApi.WageSuggestRegulationsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.WageService/SuggestRegulations', params, methodInfo, options);
    },
    SuggestPositions: (params: PlatformAdminApi.IWageSuggestPositionsRequest, options: Options = {}): Promise<PlatformAdminApi.WageSuggestPositionsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.WageSuggestPositionsResponse,
        (request: PlatformAdminApi.WageSuggestPositionsRequest) => PlatformAdminApi.WageSuggestPositionsRequest.encode(request).finish(),
        PlatformAdminApi.WageSuggestPositionsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.WageService/SuggestPositions', params, methodInfo, options);
    },
    SuggestRegions: (params: PlatformAdminApi.IWageSuggestRegionsRequest, options: Options = {}): Promise<PlatformAdminApi.WageSuggestRegionsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.WageSuggestRegionsResponse,
        (request: PlatformAdminApi.WageSuggestRegionsRequest) => PlatformAdminApi.WageSuggestRegionsRequest.encode(request).finish(),
        PlatformAdminApi.WageSuggestRegionsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.WageService/SuggestRegions', params, methodInfo, options);
    },
    SuggestOptions: (params: PlatformAdminApi.IWageSuggestOptionsRequest, options: Options = {}): Promise<PlatformAdminApi.WageSuggestOptionsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.WageSuggestOptionsResponse,
        (request: PlatformAdminApi.WageSuggestOptionsRequest) => PlatformAdminApi.WageSuggestOptionsRequest.encode(request).finish(),
        PlatformAdminApi.WageSuggestOptionsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.WageService/SuggestOptions', params, methodInfo, options);
    },
  };
  public BlockingReasonsService = {
    Create: (params: PlatformAdminApi.IBlockingReasonCreateRequest, options: Options = {}): Promise<PlatformAdminApi.BlockingReasonResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.BlockingReasonResponse,
        (request: PlatformAdminApi.BlockingReasonCreateRequest) => PlatformAdminApi.BlockingReasonCreateRequest.encode(request).finish(),
        PlatformAdminApi.BlockingReasonResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.BlockingReasonsService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IBlockingReasonUpdateRequest, options: Options = {}): Promise<PlatformAdminApi.BlockingReasonResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.BlockingReasonResponse,
        (request: PlatformAdminApi.BlockingReasonUpdateRequest) => PlatformAdminApi.BlockingReasonUpdateRequest.encode(request).finish(),
        PlatformAdminApi.BlockingReasonResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.BlockingReasonsService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.IBlockingReasonSearchRequest, options: Options = {}): Promise<PlatformAdminApi.BlockingReasonSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.BlockingReasonSearchResponse,
        (request: PlatformAdminApi.BlockingReasonSearchRequest) => PlatformAdminApi.BlockingReasonSearchRequest.encode(request).finish(),
        PlatformAdminApi.BlockingReasonSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.BlockingReasonsService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.BlockingReasonResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.BlockingReasonResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.BlockingReasonResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.BlockingReasonsService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IBlockingReasonRemoveRequest, options: Options = {}): Promise<PlatformAdminApi.BlockingReasonResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.BlockingReasonResponse,
        (request: PlatformAdminApi.BlockingReasonRemoveRequest) => PlatformAdminApi.BlockingReasonRemoveRequest.encode(request).finish(),
        PlatformAdminApi.BlockingReasonResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.BlockingReasonsService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IBlockingReasonRestoreRequest, options: Options = {}): Promise<PlatformAdminApi.BlockingReasonResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.BlockingReasonResponse,
        (request: PlatformAdminApi.BlockingReasonRestoreRequest) => PlatformAdminApi.BlockingReasonRestoreRequest.encode(request).finish(),
        PlatformAdminApi.BlockingReasonResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.BlockingReasonsService/Restore', params, methodInfo, options);
    },
  };
  public ContractService = {
    Create: (params: PlatformAdminApi.IContractCreatePage, options: Options = {}): Promise<PlatformAdminApi.ContractResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractResponse,
        (request: PlatformAdminApi.ContractCreatePage) => PlatformAdminApi.ContractCreatePage.encode(request).finish(),
        PlatformAdminApi.ContractResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IContractEditPage, options: Options = {}): Promise<PlatformAdminApi.ContractResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractResponse,
        (request: PlatformAdminApi.ContractEditPage) => PlatformAdminApi.ContractEditPage.encode(request).finish(),
        PlatformAdminApi.ContractResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.IContractSearchRequest, options: Options = {}): Promise<PlatformAdminApi.ContractsList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractsList,
        (request: PlatformAdminApi.ContractSearchRequest) => PlatformAdminApi.ContractSearchRequest.encode(request).finish(),
        PlatformAdminApi.ContractsList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ContractView> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractView,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ContractView.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ContractView> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractView,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ContractView.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ContractView> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractView,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ContractView.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractService/Restore', params, methodInfo, options);
    },
    Block: (params: PlatformAdminApi.IContractBlockRequest, options: Options = {}): Promise<PlatformAdminApi.ContractView> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractView,
        (request: PlatformAdminApi.ContractBlockRequest) => PlatformAdminApi.ContractBlockRequest.encode(request).finish(),
        PlatformAdminApi.ContractView.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractService/Block', params, methodInfo, options);
    },
    SearchBlockingReasonGroupSuggests: (params: PlatformAdminApi.ISearchBlockingReasonGroupSuggestsRequest, options: Options = {}): Promise<PlatformAdminApi.BlockingReasonGroupSuggestsList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.BlockingReasonGroupSuggestsList,
        (request: PlatformAdminApi.SearchBlockingReasonGroupSuggestsRequest) => PlatformAdminApi.SearchBlockingReasonGroupSuggestsRequest.encode(request).finish(),
        PlatformAdminApi.BlockingReasonGroupSuggestsList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractService/SearchBlockingReasonGroupSuggests', params, methodInfo, options);
    },
    SearchBlockingReasonSuggests: (params: PlatformAdminApi.ISearchBlockingReasonSuggestsRequest, options: Options = {}): Promise<PlatformAdminApi.BlockingReasonSuggestsList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.BlockingReasonSuggestsList,
        (request: PlatformAdminApi.SearchBlockingReasonSuggestsRequest) => PlatformAdminApi.SearchBlockingReasonSuggestsRequest.encode(request).finish(),
        PlatformAdminApi.BlockingReasonSuggestsList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractService/SearchBlockingReasonSuggests', params, methodInfo, options);
    },
    SearchWageSuggests: (params: PlatformAdminApi.ISearchWageSuggestsRequest, options: Options = {}): Promise<PlatformAdminApi.WageSuggestsList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.WageSuggestsList,
        (request: PlatformAdminApi.SearchWageSuggestsRequest) => PlatformAdminApi.SearchWageSuggestsRequest.encode(request).finish(),
        PlatformAdminApi.WageSuggestsList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractService/SearchWageSuggests', params, methodInfo, options);
    },
    SearchSkillSuggests: (params: PlatformAdminApi.ISearchSkillSuggestsRequest, options: Options = {}): Promise<PlatformAdminApi.SkillSuggestsList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SkillSuggestsList,
        (request: PlatformAdminApi.SearchSkillSuggestsRequest) => PlatformAdminApi.SearchSkillSuggestsRequest.encode(request).finish(),
        PlatformAdminApi.SkillSuggestsList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractService/SearchSkillSuggests', params, methodInfo, options);
    },
    RetrieveEditContract: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ContractEditPage> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractEditPage,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ContractEditPage.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractService/RetrieveEditContract', params, methodInfo, options);
    },
    SearchProductFilterOptions: (params: PlatformAdminApi.ISearchProductFilterOptionsRequest, options: Options = {}): Promise<PlatformAdminApi.ProductFilterOptionsList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ProductFilterOptionsList,
        (request: PlatformAdminApi.SearchProductFilterOptionsRequest) => PlatformAdminApi.SearchProductFilterOptionsRequest.encode(request).finish(),
        PlatformAdminApi.ProductFilterOptionsList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractService/SearchProductFilterOptions', params, methodInfo, options);
    },
    SearchSpecializationFilterOptions: (params: PlatformAdminApi.ISearchSpecializationFilterOptionsRequest, options: Options = {}): Promise<PlatformAdminApi.SpecializationFilterOptionsList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SpecializationFilterOptionsList,
        (request: PlatformAdminApi.SearchSpecializationFilterOptionsRequest) => PlatformAdminApi.SearchSpecializationFilterOptionsRequest.encode(request).finish(),
        PlatformAdminApi.SpecializationFilterOptionsList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractService/SearchSpecializationFilterOptions', params, methodInfo, options);
    },
    SearchOldVersion: (params: PlatformAdminApi.IContractSearchRequest, options: Options = {}): Promise<PlatformAdminApi.ContractSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractSearchResponse,
        (request: PlatformAdminApi.ContractSearchRequest) => PlatformAdminApi.ContractSearchRequest.encode(request).finish(),
        PlatformAdminApi.ContractSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractService/SearchOldVersion', params, methodInfo, options);
    },
  };
  public ContractorService = {
    Create: (params: PlatformAdminApi.IContractorCreateRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorResponse,
        (request: PlatformAdminApi.ContractorCreateRequest) => PlatformAdminApi.ContractorCreateRequest.encode(request).finish(),
        PlatformAdminApi.ContractorResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IContractorUpdateRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorResponse,
        (request: PlatformAdminApi.ContractorUpdateRequest) => PlatformAdminApi.ContractorUpdateRequest.encode(request).finish(),
        PlatformAdminApi.ContractorResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.IContractorSearchRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorSearchResponse,
        (request: PlatformAdminApi.ContractorSearchRequest) => PlatformAdminApi.ContractorSearchRequest.encode(request).finish(),
        PlatformAdminApi.ContractorSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ContractorResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ContractorResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ContractorResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorService/Restore', params, methodInfo, options);
    },
    Block: (params: PlatformAdminApi.IContractorBlockRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorResponse,
        (request: PlatformAdminApi.ContractorBlockRequest) => PlatformAdminApi.ContractorBlockRequest.encode(request).finish(),
        PlatformAdminApi.ContractorResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorService/Block', params, methodInfo, options);
    },
    Activate: (params: PlatformAdminApi.IContractorActivateRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorResponse,
        (request: PlatformAdminApi.ContractorActivateRequest) => PlatformAdminApi.ContractorActivateRequest.encode(request).finish(),
        PlatformAdminApi.ContractorResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorService/Activate', params, methodInfo, options);
    },
    AddTask: (params: PlatformAdminApi.IHrmContractorAddTaskRequest, options: Options = {}): Promise<PlatformAdminApi.HrmContractorAddTaskResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.HrmContractorAddTaskResponse,
        (request: PlatformAdminApi.HrmContractorAddTaskRequest) => PlatformAdminApi.HrmContractorAddTaskRequest.encode(request).finish(),
        PlatformAdminApi.HrmContractorAddTaskResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorService/AddTask', params, methodInfo, options);
    },
  };
  public RevisionHistoryService = {
    Search: (params: PlatformAdminApi.IRevisionHistorySearchRequest, options: Options = {}): Promise<PlatformAdminApi.RevisionHistorySearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.RevisionHistorySearchResponse,
        (request: PlatformAdminApi.RevisionHistorySearchRequest) => PlatformAdminApi.RevisionHistorySearchRequest.encode(request).finish(),
        PlatformAdminApi.RevisionHistorySearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.RevisionHistoryService/Search', params, methodInfo, options);
    },
  };
  public ApiService = {
    Upsert: (params: PlatformAdminApi.IApiUpsertRequest, options: Options = {}): Promise<PlatformAdminApi.ApiResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ApiResponse,
        (request: PlatformAdminApi.ApiUpsertRequest) => PlatformAdminApi.ApiUpsertRequest.encode(request).finish(),
        PlatformAdminApi.ApiResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ApiService/Upsert', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.IApiSearchRequest, options: Options = {}): Promise<PlatformAdminApi.ApiSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ApiSearchResponse,
        (request: PlatformAdminApi.ApiSearchRequest) => PlatformAdminApi.ApiSearchRequest.encode(request).finish(),
        PlatformAdminApi.ApiSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ApiService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ApiResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ApiResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ApiResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ApiService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ApiResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ApiResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ApiResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ApiService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ApiResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ApiResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ApiResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ApiService/Restore', params, methodInfo, options);
    },
  };
  public MarketService = {
    Create: (params: PlatformAdminApi.ICreateServiceMarketRequest, options: Options = {}): Promise<PlatformAdminApi.ServiceMarketResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ServiceMarketResponse,
        (request: PlatformAdminApi.CreateServiceMarketRequest) => PlatformAdminApi.CreateServiceMarketRequest.encode(request).finish(),
        PlatformAdminApi.ServiceMarketResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.MarketService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IUpdateServiceMarketRequest, options: Options = {}): Promise<PlatformAdminApi.ServiceMarketResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ServiceMarketResponse,
        (request: PlatformAdminApi.UpdateServiceMarketRequest) => PlatformAdminApi.UpdateServiceMarketRequest.encode(request).finish(),
        PlatformAdminApi.ServiceMarketResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.MarketService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.IMarketSearchRequest, options: Options = {}): Promise<PlatformAdminApi.ServicesMarketList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ServicesMarketList,
        (request: PlatformAdminApi.MarketSearchRequest) => PlatformAdminApi.MarketSearchRequest.encode(request).finish(),
        PlatformAdminApi.ServicesMarketList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.MarketService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ServiceMarketResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ServiceMarketResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ServiceMarketResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.MarketService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ServiceMarketResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ServiceMarketResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ServiceMarketResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.MarketService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ServiceMarketResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ServiceMarketResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ServiceMarketResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.MarketService/Restore', params, methodInfo, options);
    },
  };
  public AppService = {
    Create: (params: PlatformAdminApi.IAppCreateRequest, options: Options = {}): Promise<PlatformAdminApi.AppResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.AppResponse,
        (request: PlatformAdminApi.AppCreateRequest) => PlatformAdminApi.AppCreateRequest.encode(request).finish(),
        PlatformAdminApi.AppResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.AppService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IAppUpdateRequest, options: Options = {}): Promise<PlatformAdminApi.AppResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.AppResponse,
        (request: PlatformAdminApi.AppUpdateRequest) => PlatformAdminApi.AppUpdateRequest.encode(request).finish(),
        PlatformAdminApi.AppResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.AppService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.IAppSearchRequest, options: Options = {}): Promise<PlatformAdminApi.AppSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.AppSearchResponse,
        (request: PlatformAdminApi.AppSearchRequest) => PlatformAdminApi.AppSearchRequest.encode(request).finish(),
        PlatformAdminApi.AppSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.AppService/Search', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.AppResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.AppResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.AppResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.AppService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.AppResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.AppResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.AppResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.AppService/Restore', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.AppResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.AppResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.AppResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.AppService/FindById', params, methodInfo, options);
    },
    RegenerateSecret: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.AppResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.AppResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.AppResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.AppService/RegenerateSecret', params, methodInfo, options);
    },
  };
  public TenantService = {
    Search: (params: PlatformAdminApi.ITenantSearchRequest, options: Options = {}): Promise<PlatformAdminApi.TenantSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TenantSearchResponse,
        (request: PlatformAdminApi.TenantSearchRequest) => PlatformAdminApi.TenantSearchRequest.encode(request).finish(),
        PlatformAdminApi.TenantSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TenantService/Search', params, methodInfo, options);
    },
    Create: (params: PlatformAdminApi.ITenantCreateRequest, options: Options = {}): Promise<PlatformAdminApi.TenantResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TenantResponse,
        (request: PlatformAdminApi.TenantCreateRequest) => PlatformAdminApi.TenantCreateRequest.encode(request).finish(),
        PlatformAdminApi.TenantResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TenantService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.ITenantUpdateRequest, options: Options = {}): Promise<PlatformAdminApi.TenantResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TenantResponse,
        (request: PlatformAdminApi.TenantUpdateRequest) => PlatformAdminApi.TenantUpdateRequest.encode(request).finish(),
        PlatformAdminApi.TenantResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TenantService/Update', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.TenantResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TenantResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.TenantResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TenantService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.TenantResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TenantResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.TenantResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TenantService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.TenantResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TenantResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.TenantResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TenantService/Restore', params, methodInfo, options);
    },
  };
  public ProfileManagementService = {
    Create: (params: PlatformAdminApi.IProfileManagementCreateRequest, options: Options = {}): Promise<PlatformAdminApi.ProfileResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ProfileResponse,
        (request: PlatformAdminApi.ProfileManagementCreateRequest) => PlatformAdminApi.ProfileManagementCreateRequest.encode(request).finish(),
        PlatformAdminApi.ProfileResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ProfileManagementService/Create', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ProfileResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ProfileResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ProfileResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ProfileManagementService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ProfileResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ProfileResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ProfileResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ProfileManagementService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ProfileResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ProfileResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ProfileResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ProfileManagementService/Restore', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IProfileManagementUpdateRequest, options: Options = {}): Promise<PlatformAdminApi.ProfileResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ProfileResponse,
        (request: PlatformAdminApi.ProfileManagementUpdateRequest) => PlatformAdminApi.ProfileManagementUpdateRequest.encode(request).finish(),
        PlatformAdminApi.ProfileResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ProfileManagementService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.IProfileManagementSearchRequest, options: Options = {}): Promise<PlatformAdminApi.ProfileSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ProfileSearchResponse,
        (request: PlatformAdminApi.ProfileManagementSearchRequest) => PlatformAdminApi.ProfileManagementSearchRequest.encode(request).finish(),
        PlatformAdminApi.ProfileSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ProfileManagementService/Search', params, methodInfo, options);
    },
  };
  public FeedbackService = {
    Create: (params: PlatformAdminApi.IFeedbackCreateRequest, options: Options = {}): Promise<PlatformAdminApi.FeedbackResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.FeedbackResponse,
        (request: PlatformAdminApi.FeedbackCreateRequest) => PlatformAdminApi.FeedbackCreateRequest.encode(request).finish(),
        PlatformAdminApi.FeedbackResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.FeedbackService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IFeedbackUpdateRequest, options: Options = {}): Promise<PlatformAdminApi.FeedbackResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.FeedbackResponse,
        (request: PlatformAdminApi.FeedbackUpdateRequest) => PlatformAdminApi.FeedbackUpdateRequest.encode(request).finish(),
        PlatformAdminApi.FeedbackResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.FeedbackService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.IFeedbackSearchRequest, options: Options = {}): Promise<PlatformAdminApi.FeedbackSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.FeedbackSearchResponse,
        (request: PlatformAdminApi.FeedbackSearchRequest) => PlatformAdminApi.FeedbackSearchRequest.encode(request).finish(),
        PlatformAdminApi.FeedbackSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.FeedbackService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.FeedbackResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.FeedbackResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.FeedbackResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.FeedbackService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.FeedbackResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.FeedbackResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.FeedbackResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.FeedbackService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.FeedbackResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.FeedbackResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.FeedbackResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.FeedbackService/Restore', params, methodInfo, options);
    },
  };
  public OfferManagementService = {
    Create: (params: PlatformAdminApi.IOfferCreateRequest, options: Options = {}): Promise<PlatformAdminApi.OfferResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OfferResponse,
        (request: PlatformAdminApi.OfferCreateRequest) => PlatformAdminApi.OfferCreateRequest.encode(request).finish(),
        PlatformAdminApi.OfferResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OfferManagementService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IOfferUpdateRequest, options: Options = {}): Promise<PlatformAdminApi.OfferResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OfferResponse,
        (request: PlatformAdminApi.OfferUpdateRequest) => PlatformAdminApi.OfferUpdateRequest.encode(request).finish(),
        PlatformAdminApi.OfferResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OfferManagementService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.IOfferSearchRequest, options: Options = {}): Promise<PlatformAdminApi.OfferSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OfferSearchResponse,
        (request: PlatformAdminApi.OfferSearchRequest) => PlatformAdminApi.OfferSearchRequest.encode(request).finish(),
        PlatformAdminApi.OfferSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OfferManagementService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.OfferResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OfferResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.OfferResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OfferManagementService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.OfferResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OfferResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.OfferResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OfferManagementService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.OfferResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OfferResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.OfferResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OfferManagementService/Restore', params, methodInfo, options);
    },
  };
  public ManagementApiManagersService = {
    Create: (params: PlatformAdminApi.IManagerChangeRequest, options: Options = {}): Promise<PlatformAdminApi.ManagerFullResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ManagerFullResponse,
        (request: PlatformAdminApi.ManagerChangeRequest) => PlatformAdminApi.ManagerChangeRequest.encode(request).finish(),
        PlatformAdminApi.ManagerFullResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ManagementApiManagersService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IManagerChangeRequest, options: Options = {}): Promise<PlatformAdminApi.ManagerFullResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ManagerFullResponse,
        (request: PlatformAdminApi.ManagerChangeRequest) => PlatformAdminApi.ManagerChangeRequest.encode(request).finish(),
        PlatformAdminApi.ManagerFullResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ManagementApiManagersService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.IManagerSearchRequest, options: Options = {}): Promise<PlatformAdminApi.ManagerSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ManagerSearchResponse,
        (request: PlatformAdminApi.ManagerSearchRequest) => PlatformAdminApi.ManagerSearchRequest.encode(request).finish(),
        PlatformAdminApi.ManagerSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ManagementApiManagersService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ManagerFullResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ManagerFullResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ManagerFullResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ManagementApiManagersService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ManagerFullResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ManagerFullResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ManagerFullResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ManagementApiManagersService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ManagerFullResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ManagerFullResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ManagerFullResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ManagementApiManagersService/Restore', params, methodInfo, options);
    },
  };
  public OrdersService = {
    Search: (params: PlatformAdminApi.IOrderSearchRequest, options: Options = {}): Promise<PlatformAdminApi.OrderSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OrderSearchResponse,
        (request: PlatformAdminApi.OrderSearchRequest) => PlatformAdminApi.OrderSearchRequest.encode(request).finish(),
        PlatformAdminApi.OrderSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrdersService/Search', params, methodInfo, options);
    },
  };
  public CRMPersonService = {
    Create: (params: PlatformAdminApi.ICreatePersonRequest, options: Options = {}): Promise<PlatformAdminApi.PersonResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PersonResponse,
        (request: PlatformAdminApi.CreatePersonRequest) => PlatformAdminApi.CreatePersonRequest.encode(request).finish(),
        PlatformAdminApi.PersonResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CRMPersonService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IUpdatePersonRequest, options: Options = {}): Promise<PlatformAdminApi.PersonResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PersonResponse,
        (request: PlatformAdminApi.UpdatePersonRequest) => PlatformAdminApi.UpdatePersonRequest.encode(request).finish(),
        PlatformAdminApi.PersonResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CRMPersonService/Update', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.PersonResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PersonResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.PersonResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CRMPersonService/FindById', params, methodInfo, options);
    },
    SearchPerson: (params: PlatformAdminApi.ISearchPersonRequest, options: Options = {}): Promise<PlatformAdminApi.SearchPersonResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SearchPersonResponse,
        (request: PlatformAdminApi.SearchPersonRequest) => PlatformAdminApi.SearchPersonRequest.encode(request).finish(),
        PlatformAdminApi.SearchPersonResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CRMPersonService/SearchPerson', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.PersonResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PersonResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.PersonResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CRMPersonService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.PersonResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PersonResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.PersonResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CRMPersonService/Restore', params, methodInfo, options);
    },
  };
  public ProspectsService = {
    Create: (params: PlatformAdminApi.IProspect, options: Options = {}): Promise<PlatformAdminApi.ProspectResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ProspectResponse,
        (request: PlatformAdminApi.Prospect) => PlatformAdminApi.Prospect.encode(request).finish(),
        PlatformAdminApi.ProspectResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ProspectsService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IProspect, options: Options = {}): Promise<PlatformAdminApi.ProspectResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ProspectResponse,
        (request: PlatformAdminApi.Prospect) => PlatformAdminApi.Prospect.encode(request).finish(),
        PlatformAdminApi.ProspectResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ProspectsService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.IProspectSearchRequest, options: Options = {}): Promise<PlatformAdminApi.ProspectSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ProspectSearchResponse,
        (request: PlatformAdminApi.ProspectSearchRequest) => PlatformAdminApi.ProspectSearchRequest.encode(request).finish(),
        PlatformAdminApi.ProspectSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ProspectsService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IProspectId, options: Options = {}): Promise<PlatformAdminApi.ProspectResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ProspectResponse,
        (request: PlatformAdminApi.ProspectId) => PlatformAdminApi.ProspectId.encode(request).finish(),
        PlatformAdminApi.ProspectResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ProspectsService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IProspectId, options: Options = {}): Promise<PlatformAdminApi.ProspectResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ProspectResponse,
        (request: PlatformAdminApi.ProspectId) => PlatformAdminApi.ProspectId.encode(request).finish(),
        PlatformAdminApi.ProspectResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ProspectsService/Remove', params, methodInfo, options);
    },
    LogicRemove: (params: PlatformAdminApi.IProspectId, options: Options = {}): Promise<PlatformAdminApi.ProspectResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ProspectResponse,
        (request: PlatformAdminApi.ProspectId) => PlatformAdminApi.ProspectId.encode(request).finish(),
        PlatformAdminApi.ProspectResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ProspectsService/LogicRemove', params, methodInfo, options);
    },
    LogicRestore: (params: PlatformAdminApi.IProspectId, options: Options = {}): Promise<PlatformAdminApi.ProspectResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ProspectResponse,
        (request: PlatformAdminApi.ProspectId) => PlatformAdminApi.ProspectId.encode(request).finish(),
        PlatformAdminApi.ProspectResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ProspectsService/LogicRestore', params, methodInfo, options);
    },
  };
  public ManagementApiSkillsService = {
    Create: (params: PlatformAdminApi.IManagerSkill, options: Options = {}): Promise<PlatformAdminApi.ManagerSkillResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ManagerSkillResponse,
        (request: PlatformAdminApi.ManagerSkill) => PlatformAdminApi.ManagerSkill.encode(request).finish(),
        PlatformAdminApi.ManagerSkillResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ManagementApiSkillsService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IManagerSkill, options: Options = {}): Promise<PlatformAdminApi.ManagerSkillResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ManagerSkillResponse,
        (request: PlatformAdminApi.ManagerSkill) => PlatformAdminApi.ManagerSkill.encode(request).finish(),
        PlatformAdminApi.ManagerSkillResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ManagementApiSkillsService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.ISearchManagerSkillRequest, options: Options = {}): Promise<PlatformAdminApi.SearchManagerSkillResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SearchManagerSkillResponse,
        (request: PlatformAdminApi.SearchManagerSkillRequest) => PlatformAdminApi.SearchManagerSkillRequest.encode(request).finish(),
        PlatformAdminApi.SearchManagerSkillResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ManagementApiSkillsService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ManagerSkillResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ManagerSkillResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ManagerSkillResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ManagementApiSkillsService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ManagerSkillResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ManagerSkillResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ManagerSkillResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ManagementApiSkillsService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ManagerSkillResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ManagerSkillResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ManagerSkillResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ManagementApiSkillsService/Restore', params, methodInfo, options);
    },
  };
  public LoyaltyBonusService = {
    GetUserOperations: (params: PlatformAdminApi.IGetUserOperationsRequest, options: Options = {}): Promise<PlatformAdminApi.GetLoyaltyBonusOperationsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.GetLoyaltyBonusOperationsResponse,
        (request: PlatformAdminApi.GetUserOperationsRequest) => PlatformAdminApi.GetUserOperationsRequest.encode(request).finish(),
        PlatformAdminApi.GetLoyaltyBonusOperationsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.LoyaltyBonusService/GetUserOperations', params, methodInfo, options);
    },
    SearchOperations: (params: PlatformAdminApi.ISearchOperationsRequest, options: Options = {}): Promise<PlatformAdminApi.OperationsListResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OperationsListResponse,
        (request: PlatformAdminApi.SearchOperationsRequest) => PlatformAdminApi.SearchOperationsRequest.encode(request).finish(),
        PlatformAdminApi.OperationsListResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.LoyaltyBonusService/SearchOperations', params, methodInfo, options);
    },
    FindAccount: (params: PlatformAdminApi.IGetAccountRequest, options: Options = {}): Promise<PlatformAdminApi.GetAccountResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.GetAccountResponse,
        (request: PlatformAdminApi.GetAccountRequest) => PlatformAdminApi.GetAccountRequest.encode(request).finish(),
        PlatformAdminApi.GetAccountResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.LoyaltyBonusService/FindAccount', params, methodInfo, options);
    },
    FindOperationById: (params: PlatformAdminApi.IFindOperationByIdRequest, options: Options = {}): Promise<PlatformAdminApi.FindOperationByIdResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.FindOperationByIdResponse,
        (request: PlatformAdminApi.FindOperationByIdRequest) => PlatformAdminApi.FindOperationByIdRequest.encode(request).finish(),
        PlatformAdminApi.FindOperationByIdResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.LoyaltyBonusService/FindOperationById', params, methodInfo, options);
    },
    Accrue: (params: PlatformAdminApi.ICreateOperationRequest, options: Options = {}): Promise<PlatformAdminApi.CreateOperationResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CreateOperationResponse,
        (request: PlatformAdminApi.CreateOperationRequest) => PlatformAdminApi.CreateOperationRequest.encode(request).finish(),
        PlatformAdminApi.CreateOperationResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.LoyaltyBonusService/Accrue', params, methodInfo, options);
    },
    ChargeOff: (params: PlatformAdminApi.ICreateOperationRequest, options: Options = {}): Promise<PlatformAdminApi.CreateOperationResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CreateOperationResponse,
        (request: PlatformAdminApi.CreateOperationRequest) => PlatformAdminApi.CreateOperationRequest.encode(request).finish(),
        PlatformAdminApi.CreateOperationResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.LoyaltyBonusService/ChargeOff', params, methodInfo, options);
    },
    GetBonusAccountInfo: (params: PlatformAdminApi.IGetBonusAccountInfoRequest, options: Options = {}): Promise<PlatformAdminApi.GetBonusAccountInfoResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.GetBonusAccountInfoResponse,
        (request: PlatformAdminApi.GetBonusAccountInfoRequest) => PlatformAdminApi.GetBonusAccountInfoRequest.encode(request).finish(),
        PlatformAdminApi.GetBonusAccountInfoResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.LoyaltyBonusService/GetBonusAccountInfo', params, methodInfo, options);
    },
    GetAvailableOperationReasons: (params: PlatformAdminApi.IEmpty, options: Options = {}): Promise<PlatformAdminApi.GetAvailableOperationReasonsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.GetAvailableOperationReasonsResponse,
        (request: PlatformAdminApi.Empty) => PlatformAdminApi.Empty.encode(request).finish(),
        PlatformAdminApi.GetAvailableOperationReasonsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.LoyaltyBonusService/GetAvailableOperationReasons', params, methodInfo, options);
    },
  };
  public OptionService = {
    Create: (params: PlatformAdminApi.ICreateOptionRequest, options: Options = {}): Promise<PlatformAdminApi.OptionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OptionResponse,
        (request: PlatformAdminApi.CreateOptionRequest) => PlatformAdminApi.CreateOptionRequest.encode(request).finish(),
        PlatformAdminApi.OptionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OptionService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IUpdateOptionRequest, options: Options = {}): Promise<PlatformAdminApi.OptionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OptionResponse,
        (request: PlatformAdminApi.UpdateOptionRequest) => PlatformAdminApi.UpdateOptionRequest.encode(request).finish(),
        PlatformAdminApi.OptionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OptionService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.ISearchOptionRequest, options: Options = {}): Promise<PlatformAdminApi.SearchOptionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SearchOptionResponse,
        (request: PlatformAdminApi.SearchOptionRequest) => PlatformAdminApi.SearchOptionRequest.encode(request).finish(),
        PlatformAdminApi.SearchOptionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OptionService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.OptionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OptionResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.OptionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OptionService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.OptionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OptionResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.OptionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OptionService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.OptionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OptionResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.OptionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OptionService/Restore', params, methodInfo, options);
    },
  };
  public RegulationsService = {
    Create: (params: PlatformAdminApi.ICreateRegulationsRequest, options: Options = {}): Promise<PlatformAdminApi.RegulationsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.RegulationsResponse,
        (request: PlatformAdminApi.CreateRegulationsRequest) => PlatformAdminApi.CreateRegulationsRequest.encode(request).finish(),
        PlatformAdminApi.RegulationsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.RegulationsService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IUpdateRegulationsRequest, options: Options = {}): Promise<PlatformAdminApi.RegulationsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.RegulationsResponse,
        (request: PlatformAdminApi.UpdateRegulationsRequest) => PlatformAdminApi.UpdateRegulationsRequest.encode(request).finish(),
        PlatformAdminApi.RegulationsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.RegulationsService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.ISearchRegulationsRequest, options: Options = {}): Promise<PlatformAdminApi.SearchRegulationsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SearchRegulationsResponse,
        (request: PlatformAdminApi.SearchRegulationsRequest) => PlatformAdminApi.SearchRegulationsRequest.encode(request).finish(),
        PlatformAdminApi.SearchRegulationsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.RegulationsService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.RegulationsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.RegulationsResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.RegulationsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.RegulationsService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.RegulationsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.RegulationsResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.RegulationsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.RegulationsService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.RegulationsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.RegulationsResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.RegulationsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.RegulationsService/Restore', params, methodInfo, options);
    },
  };
  public UnitService = {
    Search: (params: PlatformAdminApi.ISearchUnitRequest, options: Options = {}): Promise<PlatformAdminApi.SearchUnitResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SearchUnitResponse,
        (request: PlatformAdminApi.SearchUnitRequest) => PlatformAdminApi.SearchUnitRequest.encode(request).finish(),
        PlatformAdminApi.SearchUnitResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.UnitService/Search', params, methodInfo, options);
    },
    Create: (params: PlatformAdminApi.ICreateUnitRequest, options: Options = {}): Promise<PlatformAdminApi.UnitResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.UnitResponse,
        (request: PlatformAdminApi.CreateUnitRequest) => PlatformAdminApi.CreateUnitRequest.encode(request).finish(),
        PlatformAdminApi.UnitResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.UnitService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IUpdateUnitRequest, options: Options = {}): Promise<PlatformAdminApi.UnitResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.UnitResponse,
        (request: PlatformAdminApi.UpdateUnitRequest) => PlatformAdminApi.UpdateUnitRequest.encode(request).finish(),
        PlatformAdminApi.UnitResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.UnitService/Update', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUnitIdRequest, options: Options = {}): Promise<PlatformAdminApi.UnitResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.UnitResponse,
        (request: PlatformAdminApi.UnitIdRequest) => PlatformAdminApi.UnitIdRequest.encode(request).finish(),
        PlatformAdminApi.UnitResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.UnitService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUnitIdRequest, options: Options = {}): Promise<PlatformAdminApi.UnitResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.UnitResponse,
        (request: PlatformAdminApi.UnitIdRequest) => PlatformAdminApi.UnitIdRequest.encode(request).finish(),
        PlatformAdminApi.UnitResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.UnitService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUnitIdRequest, options: Options = {}): Promise<PlatformAdminApi.UnitResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.UnitResponse,
        (request: PlatformAdminApi.UnitIdRequest) => PlatformAdminApi.UnitIdRequest.encode(request).finish(),
        PlatformAdminApi.UnitResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.UnitService/Restore', params, methodInfo, options);
    },
  };
  public RegionService = {
    Search: (params: PlatformAdminApi.ISearchRegionRequest, options: Options = {}): Promise<PlatformAdminApi.SearchRegionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SearchRegionResponse,
        (request: PlatformAdminApi.SearchRegionRequest) => PlatformAdminApi.SearchRegionRequest.encode(request).finish(),
        PlatformAdminApi.SearchRegionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.RegionService/Search', params, methodInfo, options);
    },
    Create: (params: PlatformAdminApi.ICreateRegionRequest, options: Options = {}): Promise<PlatformAdminApi.RegionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.RegionResponse,
        (request: PlatformAdminApi.CreateRegionRequest) => PlatformAdminApi.CreateRegionRequest.encode(request).finish(),
        PlatformAdminApi.RegionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.RegionService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IUpdateRegionRequest, options: Options = {}): Promise<PlatformAdminApi.RegionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.RegionResponse,
        (request: PlatformAdminApi.UpdateRegionRequest) => PlatformAdminApi.UpdateRegionRequest.encode(request).finish(),
        PlatformAdminApi.RegionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.RegionService/Update', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IRegionIdRequest, options: Options = {}): Promise<PlatformAdminApi.RegionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.RegionResponse,
        (request: PlatformAdminApi.RegionIdRequest) => PlatformAdminApi.RegionIdRequest.encode(request).finish(),
        PlatformAdminApi.RegionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.RegionService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IRegionIdRequest, options: Options = {}): Promise<PlatformAdminApi.RegionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.RegionResponse,
        (request: PlatformAdminApi.RegionIdRequest) => PlatformAdminApi.RegionIdRequest.encode(request).finish(),
        PlatformAdminApi.RegionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.RegionService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IRegionIdRequest, options: Options = {}): Promise<PlatformAdminApi.RegionResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.RegionResponse,
        (request: PlatformAdminApi.RegionIdRequest) => PlatformAdminApi.RegionIdRequest.encode(request).finish(),
        PlatformAdminApi.RegionResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.RegionService/Restore', params, methodInfo, options);
    },
  };
  public TagService = {
    Create: (params: PlatformAdminApi.ICreateTagRequest, options: Options = {}): Promise<PlatformAdminApi.TagResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TagResponse,
        (request: PlatformAdminApi.CreateTagRequest) => PlatformAdminApi.CreateTagRequest.encode(request).finish(),
        PlatformAdminApi.TagResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TagService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IUpdateTagRequest, options: Options = {}): Promise<PlatformAdminApi.TagResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TagResponse,
        (request: PlatformAdminApi.UpdateTagRequest) => PlatformAdminApi.UpdateTagRequest.encode(request).finish(),
        PlatformAdminApi.TagResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TagService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.ISearchTagRequest, options: Options = {}): Promise<PlatformAdminApi.SearchTagResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SearchTagResponse,
        (request: PlatformAdminApi.SearchTagRequest) => PlatformAdminApi.SearchTagRequest.encode(request).finish(),
        PlatformAdminApi.SearchTagResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TagService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.ITagIdRequest, options: Options = {}): Promise<PlatformAdminApi.TagResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TagResponse,
        (request: PlatformAdminApi.TagIdRequest) => PlatformAdminApi.TagIdRequest.encode(request).finish(),
        PlatformAdminApi.TagResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TagService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.ITagIdRequest, options: Options = {}): Promise<PlatformAdminApi.TagResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TagResponse,
        (request: PlatformAdminApi.TagIdRequest) => PlatformAdminApi.TagIdRequest.encode(request).finish(),
        PlatformAdminApi.TagResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TagService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.ITagIdRequest, options: Options = {}): Promise<PlatformAdminApi.TagResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TagResponse,
        (request: PlatformAdminApi.TagIdRequest) => PlatformAdminApi.TagIdRequest.encode(request).finish(),
        PlatformAdminApi.TagResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TagService/Restore', params, methodInfo, options);
    },
  };
  public ElementService = {
    Search: (params: PlatformAdminApi.ISearchElementRequest, options: Options = {}): Promise<PlatformAdminApi.SearchElementResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SearchElementResponse,
        (request: PlatformAdminApi.SearchElementRequest) => PlatformAdminApi.SearchElementRequest.encode(request).finish(),
        PlatformAdminApi.SearchElementResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ElementService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ElementResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ElementResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ElementResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ElementService/FindById', params, methodInfo, options);
    },
  };
  public PriceElementService = {
    Search: (params: PlatformAdminApi.ISearchPriceElementRequest, options: Options = {}): Promise<PlatformAdminApi.SearchPriceElementResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SearchPriceElementResponse,
        (request: PlatformAdminApi.SearchPriceElementRequest) => PlatformAdminApi.SearchPriceElementRequest.encode(request).finish(),
        PlatformAdminApi.SearchPriceElementResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PriceElementService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.PriceElementResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PriceElementResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.PriceElementResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PriceElementService/FindById', params, methodInfo, options);
    },
  };
  public NotificationService = {
    Upsert: (params: PlatformAdminApi.INotificationRequest, options: Options = {}): Promise<PlatformAdminApi.Notification> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Notification,
        (request: PlatformAdminApi.NotificationRequest) => PlatformAdminApi.NotificationRequest.encode(request).finish(),
        PlatformAdminApi.Notification.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.NotificationService/Upsert', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.ISearchNotificationRequest, options: Options = {}): Promise<PlatformAdminApi.SearchNotificationResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SearchNotificationResponse,
        (request: PlatformAdminApi.SearchNotificationRequest) => PlatformAdminApi.SearchNotificationRequest.encode(request).finish(),
        PlatformAdminApi.SearchNotificationResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.NotificationService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.Notification> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Notification,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.Notification.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.NotificationService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.Notification> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Notification,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.Notification.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.NotificationService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.Notification> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Notification,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.Notification.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.NotificationService/Restore', params, methodInfo, options);
    },
    Send: (params: PlatformAdminApi.INotificationRequest, options: Options = {}): Promise<PlatformAdminApi.SendResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SendResponse,
        (request: PlatformAdminApi.NotificationRequest) => PlatformAdminApi.NotificationRequest.encode(request).finish(),
        PlatformAdminApi.SendResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.NotificationService/Send', params, methodInfo, options);
    },
  };
  public TokenService = {
    Upsert: (params: PlatformAdminApi.IUpsertTokenRequest, options: Options = {}): Promise<PlatformAdminApi.Token> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Token,
        (request: PlatformAdminApi.UpsertTokenRequest) => PlatformAdminApi.UpsertTokenRequest.encode(request).finish(),
        PlatformAdminApi.Token.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TokenService/Upsert', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.ISearchTokenRequest, options: Options = {}): Promise<PlatformAdminApi.SearchTokenResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SearchTokenResponse,
        (request: PlatformAdminApi.SearchTokenRequest) => PlatformAdminApi.SearchTokenRequest.encode(request).finish(),
        PlatformAdminApi.SearchTokenResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TokenService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.Token> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Token,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.Token.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TokenService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.Token> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Token,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.Token.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TokenService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.Token> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Token,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.Token.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TokenService/Restore', params, methodInfo, options);
    },
  };
  public PromocodeService = {
    Create: (params: PlatformAdminApi.ICreatePromocodeRequest, options: Options = {}): Promise<PlatformAdminApi.PromocodeResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PromocodeResponse,
        (request: PlatformAdminApi.CreatePromocodeRequest) => PlatformAdminApi.CreatePromocodeRequest.encode(request).finish(),
        PlatformAdminApi.PromocodeResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PromocodeService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IUpdatePromocodeRequest, options: Options = {}): Promise<PlatformAdminApi.PromocodeResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PromocodeResponse,
        (request: PlatformAdminApi.UpdatePromocodeRequest) => PlatformAdminApi.UpdatePromocodeRequest.encode(request).finish(),
        PlatformAdminApi.PromocodeResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PromocodeService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.ISearchPromocodeRequest, options: Options = {}): Promise<PlatformAdminApi.SearchPromocodeResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SearchPromocodeResponse,
        (request: PlatformAdminApi.SearchPromocodeRequest) => PlatformAdminApi.SearchPromocodeRequest.encode(request).finish(),
        PlatformAdminApi.SearchPromocodeResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PromocodeService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.PromocodeResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PromocodeResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.PromocodeResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PromocodeService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.PromocodeResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PromocodeResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.PromocodeResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PromocodeService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.PromocodeResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PromocodeResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.PromocodeResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PromocodeService/Restore', params, methodInfo, options);
    },
  };
  public CustomerService = {
    Search: (params: PlatformAdminApi.ICustomerSearchRequest, options: Options = {}): Promise<PlatformAdminApi.CustomersList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomersList,
        (request: PlatformAdminApi.CustomerSearchRequest) => PlatformAdminApi.CustomerSearchRequest.encode(request).finish(),
        PlatformAdminApi.CustomersList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.Customer> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Customer,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.Customer.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/FindById', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.ICustomerEditFrontend, options: Options = {}): Promise<PlatformAdminApi.Customer> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Customer,
        (request: PlatformAdminApi.CustomerEditFrontend) => PlatformAdminApi.CustomerEditFrontend.encode(request).finish(),
        PlatformAdminApi.Customer.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/Update', params, methodInfo, options);
    },
    RetreiveEditCustomer: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerEditFrontend> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerEditFrontend,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.CustomerEditFrontend.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/RetreiveEditCustomer', params, methodInfo, options);
    },
    SearchAddressesById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerAddressesList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerAddressesList,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.CustomerAddressesList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/SearchAddressesById', params, methodInfo, options);
    },
    CreateAddress: (params: PlatformAdminApi.ICustomerAddressCreateFrontend, options: Options = {}): Promise<PlatformAdminApi.CustomerAddressesList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerAddressesList,
        (request: PlatformAdminApi.CustomerAddressCreateFrontend) => PlatformAdminApi.CustomerAddressCreateFrontend.encode(request).finish(),
        PlatformAdminApi.CustomerAddressesList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/CreateAddress', params, methodInfo, options);
    },
    UpdateAddress: (params: PlatformAdminApi.ICustomerAddressUpdateFrontend, options: Options = {}): Promise<PlatformAdminApi.CustomerAddressesList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerAddressesList,
        (request: PlatformAdminApi.CustomerAddressUpdateFrontend) => PlatformAdminApi.CustomerAddressUpdateFrontend.encode(request).finish(),
        PlatformAdminApi.CustomerAddressesList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/UpdateAddress', params, methodInfo, options);
    },
    DeleteAddress: (params: PlatformAdminApi.IUserAddressRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerAddressesList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerAddressesList,
        (request: PlatformAdminApi.UserAddressRequest) => PlatformAdminApi.UserAddressRequest.encode(request).finish(),
        PlatformAdminApi.CustomerAddressesList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/DeleteAddress', params, methodInfo, options);
    },
    RestoreAddress: (params: PlatformAdminApi.IUserAddressRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerAddressesList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerAddressesList,
        (request: PlatformAdminApi.UserAddressRequest) => PlatformAdminApi.UserAddressRequest.encode(request).finish(),
        PlatformAdminApi.CustomerAddressesList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/RestoreAddress', params, methodInfo, options);
    },
    RetrieveEditAddress: (params: PlatformAdminApi.IUserAddressRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerAddressEditForm> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerAddressEditForm,
        (request: PlatformAdminApi.UserAddressRequest) => PlatformAdminApi.UserAddressRequest.encode(request).finish(),
        PlatformAdminApi.CustomerAddressEditForm.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/RetrieveEditAddress', params, methodInfo, options);
    },
    RetrieveViewAddress: (params: PlatformAdminApi.IUserAddressRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerViewAddress> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerViewAddress,
        (request: PlatformAdminApi.UserAddressRequest) => PlatformAdminApi.UserAddressRequest.encode(request).finish(),
        PlatformAdminApi.CustomerViewAddress.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/RetrieveViewAddress', params, methodInfo, options);
    },
    BlockProfile: (params: PlatformAdminApi.IBlockCustomerRequest, options: Options = {}): Promise<PlatformAdminApi.Customer> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Customer,
        (request: PlatformAdminApi.BlockCustomerRequest) => PlatformAdminApi.BlockCustomerRequest.encode(request).finish(),
        PlatformAdminApi.Customer.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/BlockProfile', params, methodInfo, options);
    },
    UnblockProfile: (params: PlatformAdminApi.IUnblockCustomerRequest, options: Options = {}): Promise<PlatformAdminApi.Customer> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Customer,
        (request: PlatformAdminApi.UnblockCustomerRequest) => PlatformAdminApi.UnblockCustomerRequest.encode(request).finish(),
        PlatformAdminApi.Customer.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/UnblockProfile', params, methodInfo, options);
    },
    SearchCustomerBlockingReasons: (params: PlatformAdminApi.ICustomerBlockingReasonRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerBlockingReasonResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerBlockingReasonResponse,
        (request: PlatformAdminApi.CustomerBlockingReasonRequest) => PlatformAdminApi.CustomerBlockingReasonRequest.encode(request).finish(),
        PlatformAdminApi.CustomerBlockingReasonResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/SearchCustomerBlockingReasons', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.Customer> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Customer,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.Customer.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/Remove', params, methodInfo, options);
    },
    SearchCustomerContacts: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerContactResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerContactResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.CustomerContactResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/SearchCustomerContacts', params, methodInfo, options);
    },
    RetrieveEditCustomerContact: (params: PlatformAdminApi.IFindCustomerContactByIdRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerContactResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerContactResponse,
        (request: PlatformAdminApi.FindCustomerContactByIdRequest) => PlatformAdminApi.FindCustomerContactByIdRequest.encode(request).finish(),
        PlatformAdminApi.CustomerContactResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/RetrieveEditCustomerContact', params, methodInfo, options);
    },
    UpdateContact: (params: PlatformAdminApi.ICustomerContactUpdateRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerContactResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerContactResponse,
        (request: PlatformAdminApi.CustomerContactUpdateRequest) => PlatformAdminApi.CustomerContactUpdateRequest.encode(request).finish(),
        PlatformAdminApi.CustomerContactResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/UpdateContact', params, methodInfo, options);
    },
    SetContactMain: (params: PlatformAdminApi.ISetContactMainRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerContactResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerContactResponse,
        (request: PlatformAdminApi.SetContactMainRequest) => PlatformAdminApi.SetContactMainRequest.encode(request).finish(),
        PlatformAdminApi.CustomerContactResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/SetContactMain', params, methodInfo, options);
    },
    DeleteContact: (params: PlatformAdminApi.ICustomerContactDeleteRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerContactResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerContactResponse,
        (request: PlatformAdminApi.CustomerContactDeleteRequest) => PlatformAdminApi.CustomerContactDeleteRequest.encode(request).finish(),
        PlatformAdminApi.CustomerContactResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/DeleteContact', params, methodInfo, options);
    },
    CreateContact: (params: PlatformAdminApi.ICustomerContactCreateRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerContactResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerContactResponse,
        (request: PlatformAdminApi.CustomerContactCreateRequest) => PlatformAdminApi.CustomerContactCreateRequest.encode(request).finish(),
        PlatformAdminApi.CustomerContactResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/CreateContact', params, methodInfo, options);
    },
    SearchTenant: (params: PlatformAdminApi.ITenantSearchRequestFrontend, options: Options = {}): Promise<PlatformAdminApi.TenantSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TenantSearchResponse,
        (request: PlatformAdminApi.TenantSearchRequestFrontend) => PlatformAdminApi.TenantSearchRequestFrontend.encode(request).finish(),
        PlatformAdminApi.TenantSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/SearchTenant', params, methodInfo, options);
    },
    AttachCard: (params: PlatformAdminApi.IAttachCardRequest, options: Options = {}): Promise<PlatformAdminApi.Empty> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Empty,
        (request: PlatformAdminApi.AttachCardRequest) => PlatformAdminApi.AttachCardRequest.encode(request).finish(),
        PlatformAdminApi.Empty.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/AttachCard', params, methodInfo, options);
    },
    GetCardsList: (params: PlatformAdminApi.ICardsListRequest, options: Options = {}): Promise<PlatformAdminApi.CardsListFrontend> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CardsListFrontend,
        (request: PlatformAdminApi.CardsListRequest) => PlatformAdminApi.CardsListRequest.encode(request).finish(),
        PlatformAdminApi.CardsListFrontend.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/GetCardsList', params, methodInfo, options);
    },
    GetCardDetails: (params: PlatformAdminApi.ICardDetailsRequest, options: Options = {}): Promise<PlatformAdminApi.CardViewResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CardViewResponse,
        (request: PlatformAdminApi.CardDetailsRequest) => PlatformAdminApi.CardDetailsRequest.encode(request).finish(),
        PlatformAdminApi.CardViewResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/GetCardDetails', params, methodInfo, options);
    },
    RemoveCard: (params: PlatformAdminApi.IRemoveCardRequest, options: Options = {}): Promise<PlatformAdminApi.RemoveCardResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.RemoveCardResponse,
        (request: PlatformAdminApi.RemoveCardRequest) => PlatformAdminApi.RemoveCardRequest.encode(request).finish(),
        PlatformAdminApi.RemoveCardResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/RemoveCard', params, methodInfo, options);
    },
    RootCard: (params: PlatformAdminApi.IRootCardRequest, options: Options = {}): Promise<PlatformAdminApi.RootCardChangeResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.RootCardChangeResponse,
        (request: PlatformAdminApi.RootCardRequest) => PlatformAdminApi.RootCardRequest.encode(request).finish(),
        PlatformAdminApi.RootCardChangeResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/RootCard', params, methodInfo, options);
    },
    RetrieveOrders: (params: PlatformAdminApi.IRetrieveCrmOrdersRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerOrdersListResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerOrdersListResponse,
        (request: PlatformAdminApi.RetrieveCrmOrdersRequest) => PlatformAdminApi.RetrieveCrmOrdersRequest.encode(request).finish(),
        PlatformAdminApi.CustomerOrdersListResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/RetrieveOrders', params, methodInfo, options);
    },
    RetrieveCrmOrders: (params: PlatformAdminApi.IRetrieveCrmOrdersRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerOrdersListResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerOrdersListResponse,
        (request: PlatformAdminApi.RetrieveCrmOrdersRequest) => PlatformAdminApi.RetrieveCrmOrdersRequest.encode(request).finish(),
        PlatformAdminApi.CustomerOrdersListResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/RetrieveCrmOrders', params, methodInfo, options);
    },
    RetrieveViewOrder: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerViewOrder> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerViewOrder,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.CustomerViewOrder.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/RetrieveViewOrder', params, methodInfo, options);
    },
    ChangeCommunicationChannels: (params: PlatformAdminApi.IChangeCommunicationChannelsRequest, options: Options = {}): Promise<PlatformAdminApi.ChangeCommunicationChannelsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ChangeCommunicationChannelsResponse,
        (request: PlatformAdminApi.ChangeCommunicationChannelsRequest) => PlatformAdminApi.ChangeCommunicationChannelsRequest.encode(request).finish(),
        PlatformAdminApi.ChangeCommunicationChannelsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/ChangeCommunicationChannels', params, methodInfo, options);
    },
    GetPreferencesByUserId: (params: PlatformAdminApi.IFindUserPreferencesByIdRequest, options: Options = {}): Promise<PlatformAdminApi.FindUserPreferencesByIdResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.FindUserPreferencesByIdResponse,
        (request: PlatformAdminApi.FindUserPreferencesByIdRequest) => PlatformAdminApi.FindUserPreferencesByIdRequest.encode(request).finish(),
        PlatformAdminApi.FindUserPreferencesByIdResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CustomerService/GetPreferencesByUserId', params, methodInfo, options);
    },
  };
  public ContractorsService = {
    ContractorsSearch: (params: PlatformAdminApi.IContractorsSearchRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorsList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorsList,
        (request: PlatformAdminApi.ContractorsSearchRequest) => PlatformAdminApi.ContractorsSearchRequest.encode(request).finish(),
        PlatformAdminApi.ContractorsList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/ContractorsSearch', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorUser> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorUser,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ContractorUser.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/FindById', params, methodInfo, options);
    },
    Block: (params: PlatformAdminApi.IBlockContractorRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorUser> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorUser,
        (request: PlatformAdminApi.BlockContractorRequest) => PlatformAdminApi.BlockContractorRequest.encode(request).finish(),
        PlatformAdminApi.ContractorUser.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/Block', params, methodInfo, options);
    },
    Unblock: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorUser> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorUser,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ContractorUser.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/Unblock', params, methodInfo, options);
    },
    SearchBlockingReasons: (params: PlatformAdminApi.IContractorBlockingReasonRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorBlockingReasonResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorBlockingReasonResponse,
        (request: PlatformAdminApi.ContractorBlockingReasonRequest) => PlatformAdminApi.ContractorBlockingReasonRequest.encode(request).finish(),
        PlatformAdminApi.ContractorBlockingReasonResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/SearchBlockingReasons', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IContractorEditFrontend, options: Options = {}): Promise<PlatformAdminApi.ContractorUser> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorUser,
        (request: PlatformAdminApi.ContractorEditFrontend) => PlatformAdminApi.ContractorEditFrontend.encode(request).finish(),
        PlatformAdminApi.ContractorUser.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/Update', params, methodInfo, options);
    },
    RetrieveEditContractor: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorEditFrontend> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorEditFrontend,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ContractorEditFrontend.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/RetrieveEditContractor', params, methodInfo, options);
    },
    SearchAddressesById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorAddressesList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorAddressesList,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ContractorAddressesList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/SearchAddressesById', params, methodInfo, options);
    },
    RetrieveViewAddress: (params: PlatformAdminApi.IUserAddressRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorViewAddress> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorViewAddress,
        (request: PlatformAdminApi.UserAddressRequest) => PlatformAdminApi.UserAddressRequest.encode(request).finish(),
        PlatformAdminApi.ContractorViewAddress.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/RetrieveViewAddress', params, methodInfo, options);
    },
    RetrieveEditAddress: (params: PlatformAdminApi.IUserAddressRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorAddressEditForm> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorAddressEditForm,
        (request: PlatformAdminApi.UserAddressRequest) => PlatformAdminApi.UserAddressRequest.encode(request).finish(),
        PlatformAdminApi.ContractorAddressEditForm.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/RetrieveEditAddress', params, methodInfo, options);
    },
    CreateAddress: (params: PlatformAdminApi.IContractorAddressCreateFrontend, options: Options = {}): Promise<PlatformAdminApi.ContractorAddressesList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorAddressesList,
        (request: PlatformAdminApi.ContractorAddressCreateFrontend) => PlatformAdminApi.ContractorAddressCreateFrontend.encode(request).finish(),
        PlatformAdminApi.ContractorAddressesList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/CreateAddress', params, methodInfo, options);
    },
    UpdateAddress: (params: PlatformAdminApi.IContractorAddressUpdateFrontend, options: Options = {}): Promise<PlatformAdminApi.ContractorAddressesList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorAddressesList,
        (request: PlatformAdminApi.ContractorAddressUpdateFrontend) => PlatformAdminApi.ContractorAddressUpdateFrontend.encode(request).finish(),
        PlatformAdminApi.ContractorAddressesList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/UpdateAddress', params, methodInfo, options);
    },
    DeleteAddress: (params: PlatformAdminApi.IUserAddressRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerAddressesList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerAddressesList,
        (request: PlatformAdminApi.UserAddressRequest) => PlatformAdminApi.UserAddressRequest.encode(request).finish(),
        PlatformAdminApi.CustomerAddressesList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/DeleteAddress', params, methodInfo, options);
    },
    RestoreAddress: (params: PlatformAdminApi.IUserAddressRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerAddressesList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerAddressesList,
        (request: PlatformAdminApi.UserAddressRequest) => PlatformAdminApi.UserAddressRequest.encode(request).finish(),
        PlatformAdminApi.CustomerAddressesList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/RestoreAddress', params, methodInfo, options);
    },
    SearchTenant: (params: PlatformAdminApi.ITenantSearchRequestFrontend, options: Options = {}): Promise<PlatformAdminApi.TenantSearchResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TenantSearchResponse,
        (request: PlatformAdminApi.TenantSearchRequestFrontend) => PlatformAdminApi.TenantSearchRequestFrontend.encode(request).finish(),
        PlatformAdminApi.TenantSearchResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/SearchTenant', params, methodInfo, options);
    },
    RetrieveOrders: (params: PlatformAdminApi.IRetrieveContractorOrdersRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorOrdersListResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorOrdersListResponse,
        (request: PlatformAdminApi.RetrieveContractorOrdersRequest) => PlatformAdminApi.RetrieveContractorOrdersRequest.encode(request).finish(),
        PlatformAdminApi.ContractorOrdersListResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/RetrieveOrders', params, methodInfo, options);
    },
    RetrieveCrmOrders: (params: PlatformAdminApi.IRetrieveContractorOrdersRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorOrdersListResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorOrdersListResponse,
        (request: PlatformAdminApi.RetrieveContractorOrdersRequest) => PlatformAdminApi.RetrieveContractorOrdersRequest.encode(request).finish(),
        PlatformAdminApi.ContractorOrdersListResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/RetrieveCrmOrders', params, methodInfo, options);
    },
    SearchContractorContacts: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorContactResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorContactResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ContractorContactResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/SearchContractorContacts', params, methodInfo, options);
    },
    CreateContact: (params: PlatformAdminApi.IContractorContactCreateRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorContactResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorContactResponse,
        (request: PlatformAdminApi.ContractorContactCreateRequest) => PlatformAdminApi.ContractorContactCreateRequest.encode(request).finish(),
        PlatformAdminApi.ContractorContactResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/CreateContact', params, methodInfo, options);
    },
    DeleteContact: (params: PlatformAdminApi.IContractorContactDeleteRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorContactResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorContactResponse,
        (request: PlatformAdminApi.ContractorContactDeleteRequest) => PlatformAdminApi.ContractorContactDeleteRequest.encode(request).finish(),
        PlatformAdminApi.ContractorContactResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/DeleteContact', params, methodInfo, options);
    },
    SetContactMain: (params: PlatformAdminApi.IUserContactRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorContactResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorContactResponse,
        (request: PlatformAdminApi.UserContactRequest) => PlatformAdminApi.UserContactRequest.encode(request).finish(),
        PlatformAdminApi.ContractorContactResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/SetContactMain', params, methodInfo, options);
    },
    GetCardsList: (params: PlatformAdminApi.ICardsListRequest, options: Options = {}): Promise<PlatformAdminApi.CardsListFrontend> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CardsListFrontend,
        (request: PlatformAdminApi.CardsListRequest) => PlatformAdminApi.CardsListRequest.encode(request).finish(),
        PlatformAdminApi.CardsListFrontend.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/GetCardsList', params, methodInfo, options);
    },
    AttachCard: (params: PlatformAdminApi.IAttachCardRequest, options: Options = {}): Promise<PlatformAdminApi.Empty> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Empty,
        (request: PlatformAdminApi.AttachCardRequest) => PlatformAdminApi.AttachCardRequest.encode(request).finish(),
        PlatformAdminApi.Empty.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/AttachCard', params, methodInfo, options);
    },
    GetCardDetails: (params: PlatformAdminApi.ICardDetailsRequest, options: Options = {}): Promise<PlatformAdminApi.CardViewResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CardViewResponse,
        (request: PlatformAdminApi.CardDetailsRequest) => PlatformAdminApi.CardDetailsRequest.encode(request).finish(),
        PlatformAdminApi.CardViewResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/GetCardDetails', params, methodInfo, options);
    },
    RemoveCard: (params: PlatformAdminApi.IRemoveCardRequest, options: Options = {}): Promise<PlatformAdminApi.RemoveCardResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.RemoveCardResponse,
        (request: PlatformAdminApi.RemoveCardRequest) => PlatformAdminApi.RemoveCardRequest.encode(request).finish(),
        PlatformAdminApi.RemoveCardResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/RemoveCard', params, methodInfo, options);
    },
    RootCard: (params: PlatformAdminApi.IRootCardRequest, options: Options = {}): Promise<PlatformAdminApi.RootCardChangeResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.RootCardChangeResponse,
        (request: PlatformAdminApi.RootCardRequest) => PlatformAdminApi.RootCardRequest.encode(request).finish(),
        PlatformAdminApi.RootCardChangeResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/RootCard', params, methodInfo, options);
    },
    GetContracts: (params: PlatformAdminApi.IContractorContractRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorContractResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorContractResponse,
        (request: PlatformAdminApi.ContractorContractRequest) => PlatformAdminApi.ContractorContractRequest.encode(request).finish(),
        PlatformAdminApi.ContractorContractResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/GetContracts', params, methodInfo, options);
    },
    SearchSkills: (params: PlatformAdminApi.IContractorSkillsRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorSkillsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorSkillsResponse,
        (request: PlatformAdminApi.ContractorSkillsRequest) => PlatformAdminApi.ContractorSkillsRequest.encode(request).finish(),
        PlatformAdminApi.ContractorSkillsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/SearchSkills', params, methodInfo, options);
    },
    SearchWages: (params: PlatformAdminApi.IContractorWagesRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorWagesResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorWagesResponse,
        (request: PlatformAdminApi.ContractorWagesRequest) => PlatformAdminApi.ContractorWagesRequest.encode(request).finish(),
        PlatformAdminApi.ContractorWagesResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/SearchWages', params, methodInfo, options);
    },
    CreateContract: (params: PlatformAdminApi.IContractorContractCreateRequest, options: Options = {}): Promise<PlatformAdminApi.Empty> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Empty,
        (request: PlatformAdminApi.ContractorContractCreateRequest) => PlatformAdminApi.ContractorContractCreateRequest.encode(request).finish(),
        PlatformAdminApi.Empty.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/CreateContract', params, methodInfo, options);
    },
    UpdateContract: (params: PlatformAdminApi.IContractorContractUpdateRequest, options: Options = {}): Promise<PlatformAdminApi.Empty> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Empty,
        (request: PlatformAdminApi.ContractorContractUpdateRequest) => PlatformAdminApi.ContractorContractUpdateRequest.encode(request).finish(),
        PlatformAdminApi.Empty.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/UpdateContract', params, methodInfo, options);
    },
    UnblockContractorAndCreateContract: (params: PlatformAdminApi.IContractorContractCreateRequest, options: Options = {}): Promise<PlatformAdminApi.Empty> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Empty,
        (request: PlatformAdminApi.ContractorContractCreateRequest) => PlatformAdminApi.ContractorContractCreateRequest.encode(request).finish(),
        PlatformAdminApi.Empty.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/UnblockContractorAndCreateContract', params, methodInfo, options);
    },
    GetContractEditInformation: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorContractInformationResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorContractInformationResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.ContractorContractInformationResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/GetContractEditInformation', params, methodInfo, options);
    },
    BlockContract: (params: PlatformAdminApi.IContractorContractBlockRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorContractBlockResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorContractBlockResponse,
        (request: PlatformAdminApi.ContractorContractBlockRequest) => PlatformAdminApi.ContractorContractBlockRequest.encode(request).finish(),
        PlatformAdminApi.ContractorContractBlockResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/BlockContract', params, methodInfo, options);
    },
    SearchContractBlockingReasonGroups: (params: PlatformAdminApi.IContractorContractSearchBlockingReasonGroupsRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsResponse,
        (request: PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsRequest) => PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsRequest.encode(request).finish(),
        PlatformAdminApi.ContractorContractSearchBlockingReasonGroupsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/SearchContractBlockingReasonGroups', params, methodInfo, options);
    },
    SearchContractBlockingReasons: (params: PlatformAdminApi.IContractorContractSearchBlockingReasonsRequest, options: Options = {}): Promise<PlatformAdminApi.ContractorContractSearchBlockingReasonsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ContractorContractSearchBlockingReasonsResponse,
        (request: PlatformAdminApi.ContractorContractSearchBlockingReasonsRequest) => PlatformAdminApi.ContractorContractSearchBlockingReasonsRequest.encode(request).finish(),
        PlatformAdminApi.ContractorContractSearchBlockingReasonsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/SearchContractBlockingReasons', params, methodInfo, options);
    },
    AddTask: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.UuidResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.UuidResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.UuidResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/AddTask', params, methodInfo, options);
    },
    ChangeCommunicationChannels: (params: PlatformAdminApi.IChangeCommunicationChannelsRequest, options: Options = {}): Promise<PlatformAdminApi.ChangeCommunicationChannelsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.ChangeCommunicationChannelsResponse,
        (request: PlatformAdminApi.ChangeCommunicationChannelsRequest) => PlatformAdminApi.ChangeCommunicationChannelsRequest.encode(request).finish(),
        PlatformAdminApi.ChangeCommunicationChannelsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/ChangeCommunicationChannels', params, methodInfo, options);
    },
    GetPreferencesByUserId: (params: PlatformAdminApi.IFindUserPreferencesByIdRequest, options: Options = {}): Promise<PlatformAdminApi.FindUserPreferencesByIdResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.FindUserPreferencesByIdResponse,
        (request: PlatformAdminApi.FindUserPreferencesByIdRequest) => PlatformAdminApi.FindUserPreferencesByIdRequest.encode(request).finish(),
        PlatformAdminApi.FindUserPreferencesByIdResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.ContractorsService/GetPreferencesByUserId', params, methodInfo, options);
    },
  };
  public PresenceAreaService = {
    Create: (params: PlatformAdminApi.ICreatePresenceAreaRequest, options: Options = {}): Promise<PlatformAdminApi.PresenceArea> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PresenceArea,
        (request: PlatformAdminApi.CreatePresenceAreaRequest) => PlatformAdminApi.CreatePresenceAreaRequest.encode(request).finish(),
        PlatformAdminApi.PresenceArea.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PresenceAreaService/Create', params, methodInfo, options);
    },
    Update: (params: PlatformAdminApi.IUpdatePresenceAreaRequest, options: Options = {}): Promise<PlatformAdminApi.PresenceArea> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PresenceArea,
        (request: PlatformAdminApi.UpdatePresenceAreaRequest) => PlatformAdminApi.UpdatePresenceAreaRequest.encode(request).finish(),
        PlatformAdminApi.PresenceArea.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PresenceAreaService/Update', params, methodInfo, options);
    },
    Search: (params: PlatformAdminApi.ISearchPresenceAreaRequest, options: Options = {}): Promise<PlatformAdminApi.PresenceAreasList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PresenceAreasList,
        (request: PlatformAdminApi.SearchPresenceAreaRequest) => PlatformAdminApi.SearchPresenceAreaRequest.encode(request).finish(),
        PlatformAdminApi.PresenceAreasList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PresenceAreaService/Search', params, methodInfo, options);
    },
    FindById: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.PresenceArea> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PresenceArea,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.PresenceArea.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PresenceAreaService/FindById', params, methodInfo, options);
    },
    Remove: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.PresenceArea> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PresenceArea,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.PresenceArea.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PresenceAreaService/Remove', params, methodInfo, options);
    },
    Restore: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.PresenceArea> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PresenceArea,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.PresenceArea.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PresenceAreaService/Restore', params, methodInfo, options);
    },
  };
  public FinanceService = {
    GetPaymentMethods: (params: PlatformAdminApi.IGetPaymentMethodsRequest, options: Options = {}): Promise<PlatformAdminApi.GetPaymentMethodsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.GetPaymentMethodsResponse,
        (request: PlatformAdminApi.GetPaymentMethodsRequest) => PlatformAdminApi.GetPaymentMethodsRequest.encode(request).finish(),
        PlatformAdminApi.GetPaymentMethodsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.FinanceService/GetPaymentMethods', params, methodInfo, options);
    },
    GetPaymentTypes: (params: PlatformAdminApi.IGetPaymentTypesRequest, options: Options = {}): Promise<PlatformAdminApi.GetPaymentTypesResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.GetPaymentTypesResponse,
        (request: PlatformAdminApi.GetPaymentTypesRequest) => PlatformAdminApi.GetPaymentTypesRequest.encode(request).finish(),
        PlatformAdminApi.GetPaymentTypesResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.FinanceService/GetPaymentTypes', params, methodInfo, options);
    },
  };
  public OrderService = {
    RetrieveOrder: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerViewOrder> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerViewOrder,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.CustomerViewOrder.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/RetrieveOrder', params, methodInfo, options);
    },
    RetrieveOrderBySerialNumber: (params: PlatformAdminApi.IOrderBySerialNumberRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerViewOrder> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerViewOrder,
        (request: PlatformAdminApi.OrderBySerialNumberRequest) => PlatformAdminApi.OrderBySerialNumberRequest.encode(request).finish(),
        PlatformAdminApi.CustomerViewOrder.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/RetrieveOrderBySerialNumber', params, methodInfo, options);
    },
    RetrieveOptions: (params: PlatformAdminApi.IUuidRequest, options: Options = {}): Promise<PlatformAdminApi.OrderElementOptionsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OrderElementOptionsResponse,
        (request: PlatformAdminApi.UuidRequest) => PlatformAdminApi.UuidRequest.encode(request).finish(),
        PlatformAdminApi.OrderElementOptionsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/RetrieveOptions', params, methodInfo, options);
    },
    RetrieveElements: (params: PlatformAdminApi.IEmpty, options: Options = {}): Promise<PlatformAdminApi.OrderElementResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OrderElementResponse,
        (request: PlatformAdminApi.Empty) => PlatformAdminApi.Empty.encode(request).finish(),
        PlatformAdminApi.OrderElementResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/RetrieveElements', params, methodInfo, options);
    },
    RetrieveDiscounts: (params: PlatformAdminApi.IOrderDiscountsRequest, options: Options = {}): Promise<PlatformAdminApi.OrderDiscountsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OrderDiscountsResponse,
        (request: PlatformAdminApi.OrderDiscountsRequest) => PlatformAdminApi.OrderDiscountsRequest.encode(request).finish(),
        PlatformAdminApi.OrderDiscountsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/RetrieveDiscounts', params, methodInfo, options);
    },
    UpdateCustomerInformation: (params: PlatformAdminApi.IUpdateCustomerFIOBackend, options: Options = {}): Promise<PlatformAdminApi.Empty> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Empty,
        (request: PlatformAdminApi.UpdateCustomerFIOBackend) => PlatformAdminApi.UpdateCustomerFIOBackend.encode(request).finish(),
        PlatformAdminApi.Empty.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/UpdateCustomerInformation', params, methodInfo, options);
    },
    Upsert: (params: PlatformAdminApi.IUpsertOrderBackend, options: Options = {}): Promise<PlatformAdminApi.CustomerViewOrder> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerViewOrder,
        (request: PlatformAdminApi.UpsertOrderBackend) => PlatformAdminApi.UpsertOrderBackend.encode(request).finish(),
        PlatformAdminApi.CustomerViewOrder.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/Upsert', params, methodInfo, options);
    },
    CreateOrder: (params: PlatformAdminApi.ICreateCustomerOrderRequest, options: Options = {}): Promise<PlatformAdminApi.CreateCustomerOrderResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CreateCustomerOrderResponse,
        (request: PlatformAdminApi.CreateCustomerOrderRequest) => PlatformAdminApi.CreateCustomerOrderRequest.encode(request).finish(),
        PlatformAdminApi.CreateCustomerOrderResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/CreateOrder', params, methodInfo, options);
    },
    GetAvailableTabs: (params: PlatformAdminApi.IEmpty, options: Options = {}): Promise<PlatformAdminApi.GetAvailableTabsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.GetAvailableTabsResponse,
        (request: PlatformAdminApi.Empty) => PlatformAdminApi.Empty.encode(request).finish(),
        PlatformAdminApi.GetAvailableTabsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/GetAvailableTabs', params, methodInfo, options);
    },
    GetAvailableTimeslots: (params: PlatformAdminApi.IGetAvailableTimeslotsRequest, options: Options = {}): Promise<PlatformAdminApi.GetAvailableTimeslotsResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.GetAvailableTimeslotsResponse,
        (request: PlatformAdminApi.GetAvailableTimeslotsRequest) => PlatformAdminApi.GetAvailableTimeslotsRequest.encode(request).finish(),
        PlatformAdminApi.GetAvailableTimeslotsResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/GetAvailableTimeslots', params, methodInfo, options);
    },
    GetOrderAddresses: (params: PlatformAdminApi.IGetOrderAddressRequest, options: Options = {}): Promise<PlatformAdminApi.OrderAddressList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OrderAddressList,
        (request: PlatformAdminApi.GetOrderAddressRequest) => PlatformAdminApi.GetOrderAddressRequest.encode(request).finish(),
        PlatformAdminApi.OrderAddressList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/GetOrderAddresses', params, methodInfo, options);
    },
    CreateOrderAddress: (params: PlatformAdminApi.IUpsertOrderAddressRequest, options: Options = {}): Promise<PlatformAdminApi.OrderAddressList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OrderAddressList,
        (request: PlatformAdminApi.UpsertOrderAddressRequest) => PlatformAdminApi.UpsertOrderAddressRequest.encode(request).finish(),
        PlatformAdminApi.OrderAddressList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/CreateOrderAddress', params, methodInfo, options);
    },
    UpdateOrderAddress: (params: PlatformAdminApi.IUpsertOrderAddressRequest, options: Options = {}): Promise<PlatformAdminApi.OrderAddressList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OrderAddressList,
        (request: PlatformAdminApi.UpsertOrderAddressRequest) => PlatformAdminApi.UpsertOrderAddressRequest.encode(request).finish(),
        PlatformAdminApi.OrderAddressList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/UpdateOrderAddress', params, methodInfo, options);
    },
    DeleteOrderAddress: (params: PlatformAdminApi.IOrderAddressRequest, options: Options = {}): Promise<PlatformAdminApi.OrderAddressList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OrderAddressList,
        (request: PlatformAdminApi.OrderAddressRequest) => PlatformAdminApi.OrderAddressRequest.encode(request).finish(),
        PlatformAdminApi.OrderAddressList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/DeleteOrderAddress', params, methodInfo, options);
    },
    RestoreOrderAddress: (params: PlatformAdminApi.IOrderAddressRequest, options: Options = {}): Promise<PlatformAdminApi.OrderAddressList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OrderAddressList,
        (request: PlatformAdminApi.OrderAddressRequest) => PlatformAdminApi.OrderAddressRequest.encode(request).finish(),
        PlatformAdminApi.OrderAddressList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/RestoreOrderAddress', params, methodInfo, options);
    },
    RetrieveEditOrderAddress: (params: PlatformAdminApi.IOrderAddressRequest, options: Options = {}): Promise<PlatformAdminApi.OrderAddressForm> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OrderAddressForm,
        (request: PlatformAdminApi.OrderAddressRequest) => PlatformAdminApi.OrderAddressRequest.encode(request).finish(),
        PlatformAdminApi.OrderAddressForm.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/RetrieveEditOrderAddress', params, methodInfo, options);
    },
    RetrieveViewOrderAddress: (params: PlatformAdminApi.IOrderAddressRequest, options: Options = {}): Promise<PlatformAdminApi.OrderAddressView> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OrderAddressView,
        (request: PlatformAdminApi.OrderAddressRequest) => PlatformAdminApi.OrderAddressRequest.encode(request).finish(),
        PlatformAdminApi.OrderAddressView.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/RetrieveViewOrderAddress', params, methodInfo, options);
    },
    UpdateOrderOptions: (params: PlatformAdminApi.IUpsertOrderOptionsBackend, options: Options = {}): Promise<PlatformAdminApi.CustomerViewOrder> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerViewOrder,
        (request: PlatformAdminApi.UpsertOrderOptionsBackend) => PlatformAdminApi.UpsertOrderOptionsBackend.encode(request).finish(),
        PlatformAdminApi.CustomerViewOrder.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/UpdateOrderOptions', params, methodInfo, options);
    },
    UpdateOrderStatus: (params: PlatformAdminApi.IUpdateOrderStatusRequest, options: Options = {}): Promise<PlatformAdminApi.UpdateOrderStatusResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.UpdateOrderStatusResponse,
        (request: PlatformAdminApi.UpdateOrderStatusRequest) => PlatformAdminApi.UpdateOrderStatusRequest.encode(request).finish(),
        PlatformAdminApi.UpdateOrderStatusResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/UpdateOrderStatus', params, methodInfo, options);
    },
    ConfirmCart: (params: PlatformAdminApi.ICoreBaseCartConfirmRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerViewOrder> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerViewOrder,
        (request: PlatformAdminApi.CoreBaseCartConfirmRequest) => PlatformAdminApi.CoreBaseCartConfirmRequest.encode(request).finish(),
        PlatformAdminApi.CustomerViewOrder.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/ConfirmCart', params, methodInfo, options);
    },
    UpdateAdditionalServices: (params: PlatformAdminApi.IUpdateAdditionalServicesRequest, options: Options = {}): Promise<PlatformAdminApi.CustomerViewOrder> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerViewOrder,
        (request: PlatformAdminApi.UpdateAdditionalServicesRequest) => PlatformAdminApi.UpdateAdditionalServicesRequest.encode(request).finish(),
        PlatformAdminApi.CustomerViewOrder.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/UpdateAdditionalServices', params, methodInfo, options);
    },
    SearchAvailableContractors: (params: PlatformAdminApi.IGetContractorsForOrderRequest, options: Options = {}): Promise<PlatformAdminApi.OrderContractorsList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.OrderContractorsList,
        (request: PlatformAdminApi.GetContractorsForOrderRequest) => PlatformAdminApi.GetContractorsForOrderRequest.encode(request).finish(),
        PlatformAdminApi.OrderContractorsList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/SearchAvailableContractors', params, methodInfo, options);
    },
    UpsertAssignments: (params: PlatformAdminApi.IUpsertAssignmentBackend, options: Options = {}): Promise<PlatformAdminApi.CustomerViewOrder> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.CustomerViewOrder,
        (request: PlatformAdminApi.UpsertAssignmentBackend) => PlatformAdminApi.UpsertAssignmentBackend.encode(request).finish(),
        PlatformAdminApi.CustomerViewOrder.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OrderService/UpsertAssignments', params, methodInfo, options);
    },
  };
  public SkuService = {
    RetrieveSkus: (params: PlatformAdminApi.ISkusRequest, options: Options = {}): Promise<PlatformAdminApi.SkusList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SkusList,
        (request: PlatformAdminApi.SkusRequest) => PlatformAdminApi.SkusRequest.encode(request).finish(),
        PlatformAdminApi.SkusList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.SkuService/RetrieveSkus', params, methodInfo, options);
    },
    FindSkuById: (params: PlatformAdminApi.IIUuid, options: Options = {}): Promise<PlatformAdminApi.SkuViewItem> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SkuViewItem,
        (request: PlatformAdminApi.IUuid) => PlatformAdminApi.IUuid.encode(request).finish(),
        PlatformAdminApi.SkuViewItem.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.SkuService/FindSkuById', params, methodInfo, options);
    },
    UpdateImage: (params: PlatformAdminApi.IChangeImageRequest, options: Options = {}): Promise<PlatformAdminApi.SkuViewItem> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SkuViewItem,
        (request: PlatformAdminApi.ChangeImageRequest) => PlatformAdminApi.ChangeImageRequest.encode(request).finish(),
        PlatformAdminApi.SkuViewItem.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.SkuService/UpdateImage', params, methodInfo, options);
    },
    UpdateSku: (params: PlatformAdminApi.IUpdateSkuRequest, options: Options = {}): Promise<PlatformAdminApi.SkuViewItem> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SkuViewItem,
        (request: PlatformAdminApi.UpdateSkuRequest) => PlatformAdminApi.UpdateSkuRequest.encode(request).finish(),
        PlatformAdminApi.SkuViewItem.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.SkuService/UpdateSku', params, methodInfo, options);
    },
  };
  public OtpCodesService = {
    Search: (params: PlatformAdminApi.ISearchOtpCodesRequest, options: Options = {}): Promise<PlatformAdminApi.SearchOtpCodesResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.SearchOtpCodesResponse,
        (request: PlatformAdminApi.SearchOtpCodesRequest) => PlatformAdminApi.SearchOtpCodesRequest.encode(request).finish(),
        PlatformAdminApi.SearchOtpCodesResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.OtpCodesService/Search', params, methodInfo, options);
    },
  };
  public TemplatesService = {
    RetrieveTemplates: (params: PlatformAdminApi.ITemplatesRequest, options: Options = {}): Promise<PlatformAdminApi.TemplatesList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TemplatesList,
        (request: PlatformAdminApi.TemplatesRequest) => PlatformAdminApi.TemplatesRequest.encode(request).finish(),
        PlatformAdminApi.TemplatesList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TemplatesService/RetrieveTemplates', params, methodInfo, options);
    },
    FindParentTemplateById: (params: PlatformAdminApi.ITemplatesParentItemRequest, options: Options = {}): Promise<PlatformAdminApi.TemplatesParentItem> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TemplatesParentItem,
        (request: PlatformAdminApi.TemplatesParentItemRequest) => PlatformAdminApi.TemplatesParentItemRequest.encode(request).finish(),
        PlatformAdminApi.TemplatesParentItem.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TemplatesService/FindParentTemplateById', params, methodInfo, options);
    },
    FindChildTemplateById: (params: PlatformAdminApi.ITemplatesChildItemRequest, options: Options = {}): Promise<PlatformAdminApi.TemplatesChildItem> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.TemplatesChildItem,
        (request: PlatformAdminApi.TemplatesChildItemRequest) => PlatformAdminApi.TemplatesChildItemRequest.encode(request).finish(),
        PlatformAdminApi.TemplatesChildItem.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.TemplatesService/FindChildTemplateById', params, methodInfo, options);
    },
  };
  public WarehousesService = {
    RetrieveWarehouses: (params: PlatformAdminApi.IWarehousesRequest, options: Options = {}): Promise<PlatformAdminApi.WarehousesCatalog> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.WarehousesCatalog,
        (request: PlatformAdminApi.WarehousesRequest) => PlatformAdminApi.WarehousesRequest.encode(request).finish(),
        PlatformAdminApi.WarehousesCatalog.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.WarehousesService/RetrieveWarehouses', params, methodInfo, options);
    },
    FindWarehouseById: (params: PlatformAdminApi.IIUuid, options: Options = {}): Promise<PlatformAdminApi.Warehouse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.Warehouse,
        (request: PlatformAdminApi.IUuid) => PlatformAdminApi.IUuid.encode(request).finish(),
        PlatformAdminApi.Warehouse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.WarehousesService/FindWarehouseById', params, methodInfo, options);
    },
  };
  public CommunicationChannelsService = {
    EnableCommunicationChannel: (params: PlatformAdminApi.IEnableCommunicationChannelRequest, options: Options = {}): Promise<PlatformAdminApi.EnableCommunicationChannelResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.EnableCommunicationChannelResponse,
        (request: PlatformAdminApi.EnableCommunicationChannelRequest) => PlatformAdminApi.EnableCommunicationChannelRequest.encode(request).finish(),
        PlatformAdminApi.EnableCommunicationChannelResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CommunicationChannelsService/EnableCommunicationChannel', params, methodInfo, options);
    },
    DisableCommunicationChannel: (params: PlatformAdminApi.IDisableCommunicationChannelRequest, options: Options = {}): Promise<PlatformAdminApi.DisableCommunicationChannelResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.DisableCommunicationChannelResponse,
        (request: PlatformAdminApi.DisableCommunicationChannelRequest) => PlatformAdminApi.DisableCommunicationChannelRequest.encode(request).finish(),
        PlatformAdminApi.DisableCommunicationChannelResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CommunicationChannelsService/DisableCommunicationChannel', params, methodInfo, options);
    },
  };
  public PlacesService = {
    RetrievePlaces: (params: PlatformAdminApi.IPlacesRequest, options: Options = {}): Promise<PlatformAdminApi.WarehousesList> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.WarehousesList,
        (request: PlatformAdminApi.PlacesRequest) => PlatformAdminApi.PlacesRequest.encode(request).finish(),
        PlatformAdminApi.WarehousesList.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PlacesService/RetrievePlaces', params, methodInfo, options);
    },
    FindPlaceById: (params: PlatformAdminApi.IPlaceRequest, options: Options = {}): Promise<PlatformAdminApi.PlaceItem> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.PlaceItem,
        (request: PlatformAdminApi.PlaceRequest) => PlatformAdminApi.PlaceRequest.encode(request).finish(),
        PlatformAdminApi.PlaceItem.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.PlacesService/FindPlaceById', params, methodInfo, options);
    },
  };
  public CommunicationPreferencesService = {
    FindUserPreferences: (params: PlatformAdminApi.IFindUserPreferencesByIdRequest, options: Options = {}): Promise<PlatformAdminApi.FindUserPreferencesByIdResponse> => {
      const methodInfo = new AbstractClientBase.MethodInfo(
        PlatformAdminApi.FindUserPreferencesByIdResponse,
        (request: PlatformAdminApi.FindUserPreferencesByIdRequest) => PlatformAdminApi.FindUserPreferencesByIdRequest.encode(request).finish(),
        PlatformAdminApi.FindUserPreferencesByIdResponse.decode
      );
      return this.makeInterceptedUnaryCall(this.hostname + '/PlatformAdminApi.CommunicationPreferencesService/FindUserPreferences', params, methodInfo, options);
    },
  };
};