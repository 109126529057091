import { Menu, MENU_TYPES } from 'src/interfaces';

export default (): Menu[] => [
  {
    type: MENU_TYPES.ITEMS,
    text: 'Каталоги',
    link: '/catalogs',
    children: [
      {
        type: MENU_TYPES.ITEMS,
        text: 'SSO',
        link: '/catalogs/sso/',
        children: [
          {
            type: MENU_TYPES.ROUTER,
            text: 'Сервисы',
            link: '/catalogs/sso/market/',
          },
          {
            type: MENU_TYPES.ROUTER,
            text: 'Список Api',
            link: '/catalogs/sso/api/',
          },
          {
            type: MENU_TYPES.ROUTER,
            text: 'Арендаторы',
            link: '/catalogs/sso/tenants/',
          },
          {
            type: MENU_TYPES.ROUTER,
            text: 'Приложения',
            link: '/catalogs/sso/apps/',
          },
        ],
      },
      {
        type: MENU_TYPES.ITEMS,
        text: 'CRM',
        link: '/catalogs/crm/',
        children: [
          {
            type: MENU_TYPES.ROUTER,
            text: 'Офферы',
            link: '/catalogs/crm/offers/',
          },
          {
            type: MENU_TYPES.ROUTER,
            text: 'Клиенты',
            link: '/catalogs/crm/persons/',
          },
        ],
      },
      {
        type: MENU_TYPES.ITEMS,
        text: 'HRM',
        link: '/catalogs/hrm/',
        children: [
          {
            type: MENU_TYPES.ROUTER,
            text: 'Группы причин отключения',
            link: '/catalogs/hrm/blockingReasonsGroups/',
          },
          {
            type: MENU_TYPES.ROUTER,
            text: 'Причины отключения',
            link: '/catalogs/hrm/blockingReasons/',
          },
          {
            type: MENU_TYPES.ROUTER,
            text: 'Специализации',
            link: '/catalogs/hrm/specializations/',
          },
          {
            type: MENU_TYPES.ROUTER,
            text: 'Навыки',
            link: '/catalogs/hrm/skills/',
          },
          {
            type: MENU_TYPES.ROUTER,
            text: 'Рабочие позиции',
            link: '/catalogs/hrm/positions/',
          },
        ],
      },
      {
        type: MENU_TYPES.ITEMS,
        text: 'WMS',
        link: '/catalogs/wms/',
        children: [
          {
            type: MENU_TYPES.ROUTER,
            text: 'Номенклатура',
            link: '/catalogs/wms/skus/',
          },
          {
            type: MENU_TYPES.ROUTER,
            text: 'Шаблоны',
            link: '/catalogs/wms/templates/',
          },
          {
            type: MENU_TYPES.ROUTER,
            text: 'Склады',
            link: '/catalogs/wms/warehouses/',
          },
          {
            type: MENU_TYPES.ROUTER,
            text: 'Места',
            link: '/catalogs/wms/places/',
          },
        ],
      },
      {
        type: MENU_TYPES.ITEMS,
        text: 'Общее',
        link: '/catalogs/core',
        children: [
          {
            type: MENU_TYPES.ROUTER,
            text: 'Регламенты',
            link: '/catalogs/core/regulations/',
          },
          {
            type: MENU_TYPES.ROUTER,
            text: 'Опции',
            link: '/catalogs/core/options/',
          },
        ],
      },
      // {
      //   type: MENU_TYPES.ROUTER,
      //   text: 'Единицы измерения',
      //   link: '/catalogs/units/',
      // },
      // {
      //   type: MENU_TYPES.ITEMS,
      //   text: 'Core',
      //   link: '/catalogs/core/',
      //   children: [
      //     {
      //       type: MENU_TYPES.ROUTER,
      //       text: 'Опции',
      //       link: '/catalogs/core/options/',
      //     },
      //     {
      //       type: MENU_TYPES.ROUTER,
      //       text: 'Регламенты',
      //       link: '/catalogs/core/regulations/',
      //     },
      //     {
      //       type: MENU_TYPES.ROUTER,
      //       text: 'Теги',
      //       link: '/catalogs/core/tags/',
      //     },
      //   ],
      // },
      // {
      //   type: MENU_TYPES.ITEMS,
      //   text: 'Управление регионами',
      //   link: '/catalogs/presence/',
      //   children: [
      //     {
      //       type: MENU_TYPES.ROUTER,
      //       text: 'Регионы присутствия',
      //       link: '/catalogs/presence/area/',
      //     },
      //     {
      //       type: MENU_TYPES.ROUTER,
      //       text: 'Полигоны',
      //       link: '/catalogs/presence/polygon/',
      //     },
      //   ],
      // },
      // {
      //   type: MENU_TYPES.ITEMS,
      //   text: 'Управление уведомлениями',
      //   link: '/catalogs/communications-gateway/',
      //   children: [
      //     {
      //       type: MENU_TYPES.ROUTER,
      //       text: 'Токены',
      //       link: '/catalogs/communications-gateway/tokens/',
      //     },
      //     {
      //       type: MENU_TYPES.ROUTER,
      //       text: 'Уведомления',
      //       link: '/catalogs/communications-gateway/notifications/',
      //     },
      //   ],
      // },
    ],
  },
];
