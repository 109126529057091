import GrpcService, { PlatformAdminApi } from 'src/services/GrpcService';

import {
  ICreate,
  ISearch,
  IUpdate,
  IRestore,
  IFindById,
  IRemove,
} from 'src/services/ServiceLocator/ServiceLocator.interface';
import { RequestController } from 'src/utils/decorators/request-controller';

class RegulationsServiceProvider implements ISearch, ICreate, IUpdate, IRestore, IFindById, IRemove {
  @RequestController({ logger: true, retry: true, debounce: true })
  search(params: PlatformAdminApi.ISearchRegulationsRequest): Promise<PlatformAdminApi.ISearchRegulationsResponse> {
    return GrpcService.RegulationsService.Search(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  create(params: PlatformAdminApi.ICreateRegulationsRequest): Promise<PlatformAdminApi.RegulationsResponse> {
    return GrpcService.RegulationsService.Create(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  update(params: PlatformAdminApi.IUpdateRegulationsRequest): Promise<PlatformAdminApi.RegulationsResponse> {
    return GrpcService.RegulationsService.Update(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  restore(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.RegulationsResponse> {
    return GrpcService.RegulationsService.Restore(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  remove(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.RegulationsResponse> {
    return GrpcService.RegulationsService.Remove(params);
  }

  @RequestController({ logger: true, retry: true, debounce: true })
  findById(params: PlatformAdminApi.IUuidRequest): Promise<PlatformAdminApi.RegulationsResponse> {
    return GrpcService.RegulationsService.FindById(params);
  }
}

export const regulationsServiceProvider = new RegulationsServiceProvider();
export type TRegulationsServiceProvider = typeof regulationsServiceProvider;
